const modelData = ({key, data}) => {
  const reg = 'dbdok'
  switch (key) {
    case 'index-mKelurahan':
    case 'index-mSubdistrict':
    case 'index-mProvince':
    case 'index-mCity':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          id: data?.id,
          // log: true,
          reg,
        }),
      }
    case 'index':
      return {
        req: JSON.stringify({
          id: data?.id,
          ep: data?._ldata,
          // log: true,
          field:
            data?.field?.length > 0
              ? data?.field
              : [
                  'status',
                  'name',
                  'spesialis',
                  'fotostr',
                  'fotoktp',
                  'fotosip',
                  'noktp',
                  'npwp',
                  'nosip',
                  'nostr',
                  'nowa',
                  'address',
                  'province',
                  'city',
                  'subdistrict',
                  'kelurahan',
                  'rt',
                  'rw',
                  'kodepos',
                  'lat',
                  'lng',
                  'username',
                  'email',
                  'logo',
                  'nosatusehat',
                  'dokkode',
                ],
          reg,
        }),
      }
    case 'update':
      return {
        req: JSON.stringify({
          id: data?.id,
          // log: true,
          user: data?.user,
          file: {
            user_str: !!data?.file['fotostr-to-upload']
              ? data?.file['fotostr-to-upload'].split(',')[1]
              : null,
            user_ktp: !!data?.file['fotoktp-to-upload']
              ? data?.file['fotoktp-to-upload'].split(',')[1]
              : null,
            user_sip: !!data?.file['fotosip-to-upload']
              ? data?.file['fotosip-to-upload'].split(',')[1]
              : null,
            logo: !!data?.file['logotoupload']
              ? data?.file['logotoupload'].split(',')[1]
              : null,
          },
          ep: data?._ldata,
          reg,
        }),
      }
    default:
      return null
  }
}

export {modelData}
