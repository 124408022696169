import axios from 'axios'
import classNames from 'classnames'
import {UserController} from 'controllers'
import {FullErrorView, ProgressFullScreen} from 'molecules'
import {createRef, useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {PrdUbahProfil, TemplateForm} from 'template'
import SearchContent from 'template/Modal/SearchContent'
import FormContent from 'template/Modal/FormContent'
import {VmSuster} from 'components/icons/outline'
import {VmButton, VmView} from 'components'
import {encodeImageFileAsURL, isEmpty, toPascalCase} from 'utils'
import {mCariIdSatusehatDokter} from './api'
import QueryString from 'qs'
import {empty} from 'utils/functions'

const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  REACT_APP_BASE_URL_UTAMA: API5,
  REACT_APP_BASE_URL_SATUSEHAT: APISATUSEHAT,
  REACT_APP_BASE_URL_BPJS: APIBPJS,
} = process.env

const textclassname = '!text-slate-500 text-sm font-semibold'
const titleclassname = 'font-bold'

const Items = ({data = [], onPick = () => {}, selected = null}) => {
  return data?.map((im, idx) => {
    const key = `k-${idx}.${Date.now()}`,
      active =
        JSON.stringify({im: selected}) === JSON.stringify({im: im.kdDokter})
    return (
      <VmView
        key={key}
        onClick={() => (active ? onPick() : onPick(im))}
        className={classNames(
          'cursor-pointer',
          active ? 'border-2 border-sky-500 bg-sky-100' : '',
          'p-3 border rounded-2xl'
        )}
      >
        <VmView>{`${im?.kdDokter} - ${im?.nmDokter}` || '-'}</VmView>
      </VmView>
    )
  })
}

export default function Index() {
  const pageTitle = 'Ubah Profil'
  const navigate = useNavigate()
  const params = useParams()
  const id = params?.id
  const lstchangeddata = [
    'input-name',
    'input-spesialis',
    'input-noktp',
    'input-npwp',
    'input-nostr',
    'input-nosip',
    'input-nowa',
    'input-address',
    'input-province',
    'input-city',
    'input-subdistrict',
    'input-email',
    'input-username',
    'input-password',
    'input-new-password',
    'input-confirm-new-password',
    'input-kelurahan',
    'input-rt',
    'input-rw',
    'input-kodepos',
    'input-position',
  ]
  const lstimgdata = ['img-logo', 'img-fotoktp', 'img-fotostr', 'img-fotosip']
  const lstinputimg = [
    'input-fotoktp',
    'input-fotostr',
    'input-fotosip',
    'input-logo',
  ]
  const lstselectdata = [
    'select-province',
    'select-city',
    'select-subdistrict',
    'select-kelurahan',
  ]
  const formRef = createRef()
  const location = useLocation()
  const [active, setactive] = useState(1)
  const [customPcare, setCustomPcare] = useState(!true)
  const [changepswd, setchangepswd] = useState(false)
  const [data, setdata] = useState({
    username: '',
    idprovince: 0,
    idcity: 0,
    idsubdistrict: 0,
  })
  const [city, setcity] = useState([])
  const [province, setprovince] = useState([])
  const [subdistrict, setsubdistrict] = useState([])
  const [kelurahan, setkelurahan] = useState([])
  const [datachange, setdatachange] = useState({})
  const [datasatusehat, setdatasatusehat] = useState({})
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)
  const [initialData, setinitialData] = useState(null)

  const cekCustomBpjsPcare = async _ => {
    const uid = id
    axios
      .post(
        `${APIDOC}/backend-data-registrasi/get-custom`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data: res}) => {
        console.log('cekCustomBpjsPcare', res)
        if (res?.data) {
          if (res?.data['121']) {
            setCustomPcare(true)
          }
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Cek Custom BPJS PCare')
      })
      .finally(() => {
        // setLoadingGetCustom(false)
      })
  }

  const saveData = () => {
    setloading(true)
    var okRef = formRef.current.okRef
    UserController(
      {
        id,
        user:
          active === 2
            ? {
                email: datachange?.email,
                username: datachange?.username,
                password: datachange?.password,
                newpassword: datachange['new-password'],
              }
            : {
                ...datachange,
                ...datasatusehat,
                logBefore: initialData,
                logAfter: data,
              },
        file: data,
        key: 'update',
        dispatch: () => {},
        _ldata: active === 2 ? 'mUpdateAkunInfo' : 'mUpdateUserInfo',
      },
      navigate
    )
      .then(res => {
        if (res?.status === 1) {
          setdatachange({})
          okRef.show(
            {
              message: res?.message || 'Berhasil menyimpan data',
              title: 'Berhasil',
            },
            () => {
              if (active === 2) {
                window.location.reload()
              }
            },
            () => {
              if (active === 2) {
                window.location.reload()
              }
            }
          )
        } else {
          okRef.show(
            {
              message: res?.message || 'Gagal menyimpan data',
              title: 'Gagal',
            },
            () => {},
            () => {}
          )
        }
      })
      .catch(err => {
        // console.log('err', err)
        okRef.show(
          {
            message: '[C0] Gagal menyimpan data',
            title: 'Gagal',
          },
          () => {},
          () => {}
        )
      })
      .finally(() => {
        setloading(false)
      })
  }
  const loadData = () => {
    var error = false,
      errorMsg = ''
    seterror(false)

    return Promise.all(
      [
        {
          id,
          key: 'index',
          dispatch: () => {},
          _ldata: 'mUserInfo',
        },
        {
          key: 'index-mSubdistrict',
          dispatch: () => {},
          _ldata: 'mSubdistrict',
        },
        {
          key: 'index-mProvince',
          dispatch: () => {},
          _ldata: 'mProvince',
        },
        {
          key: 'index-mCity',
          dispatch: () => {},
          _ldata: 'mCity',
        },
        // {
        //   key: 'index-mKelurahan',
        //   dispatch: () => {},
        //   _ldata: 'mKelurahan',
        // },
      ].map(im => UserController(im, navigate))
    )
      .then(pall => {
        var [res, Subdistrict, Province, City, Kelurahan] = pall,
          newdata = {}
        if (res?.status === 1) {
          newdata = res?.data
          newdata['idcity'] = newdata['city']
          newdata['idprovince'] = newdata['province']
          newdata['idsubdistrict'] = newdata['subdistrict']
          newdata['idkelurahan'] = newdata['kelurahan']
          newdata['province'] =
            Province?.status === 1
              ? Province?.data.find(fn => fn.idprovinsi === newdata['province'])
                  ?.provinsi || '-'
              : '-'
          newdata['city'] =
            City?.status === 1
              ? Number(newdata['idcity']) > 0
                ? City?.data.find(fn => fn.idkota === newdata['city'])?.kota ||
                  '-'
                : null
              : '-'
          newdata['subdistrict'] =
            Subdistrict?.status === 1
              ? Number(newdata['idsubdistrict']) > 0
                ? Subdistrict?.data.find(
                    fn => fn.idkecamatan === newdata['subdistrict']
                  )?.kecamatan || '-'
                : null
              : '-'

          // Data kelurahan ambil via api by id kecamatan gan, gak kuat kalo load all.
          // newdata['kelurahan'] =
          //   Kelurahan?.status === 1
          //     ? Kelurahan?.data.find(
          //         fn => fn.idkelurahan === newdata['idkelurahan']
          //       )?.kelurahan || '-'
          //     : '-'
          setinitialData(res?.data)
          setdata(res?.data)
          if (Subdistrict?.status === 1) {
            setsubdistrict(
              Subdistrict?.data
                ? Subdistrict?.data?.map(im => ({
                    title: im.kecamatan,
                    id: im.idkecamatan,
                    idcity: im.idkota,
                  }))
                : []
            )
          }
          if (Province?.status === 1) {
            setprovince(
              Province?.data
                ? Province?.data?.map(im => ({
                    title: im.provinsi,
                    id: im.idprovinsi,
                  }))
                : []
            )
          }
          if (City?.status === 1) {
            setcity(
              City?.data
                ? City?.data?.map(im => ({
                    title: im.kota,
                    id: im.idkota,
                    idprovince: im.idprovinsi,
                  }))
                : []
            )
          }

          // Data kelurahan ambil via api by id kecamatan gan, gak kuat kalo load all.
          // if (Kelurahan?.status === 1) {
          //   setkelurahan(
          //     Kelurahan?.data
          //       ? Kelurahan?.data?.map(im => ({
          //           title: im.kelurahan,
          //           id: im.idkelurahan,
          //           idsubdistrict: im.idkecamatan,
          //           kodepos: im.kodepos,
          //         }))
          //       : []
          //   )
          // }
        } else {
          error = !false
          errorMsg =
            res?.message ||
            `[CE${
              pall.filter(fl => fl?.status !== 1).length
            }] Gagal saat mengambil data!`
        }
        // console.log('pall', pall)
      })
      .catch(err => {
        error = !false
        errorMsg = '[E0] Gagal saat mengambil data!'
        // console.log('err', err)
      })
      .finally(() => {
        setInitialLoading(false)
        setloading(false)
        if (error) {
          seterror(errorMsg)
        }
      })
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    var actialfield = '',
      newvalue = null,
      {yesNoRef, okRef, handleModal, modalRef} = formRef.current
    if (ariaLabel === 'search-input-nosatusehat') {
      // Validasi NIK kosong
      if (isEmpty(datachange?.noktp) && isEmpty(data?.noktp)) {
        return okRef.show(
          {
            message: 'No. KTP tidak boleh kosong!',
          },
          () => {},
          () => {}
        )
      }
      // Validasi Id Satu Sehat dengan No. Ktp
      setloading(!false)
      return mCariIdSatusehatDokter({
        input: {doknik: datachange?.noktp || data?.noktp, kl_id: id},
      })
        .then(({data: res}) => {
          // Jika ada Id satu sehat Simpan perubahan pada datasatusehat
          if (res?.status === 1) {
            if (!res?.data?.entry) {
              setdatasatusehat({nosatusehat: ''})
              return okRef.show(
                {
                  message: 'Id Satu Sehat tidak ditemukan!',
                  title: 'Peringatan',
                },
                () => {},
                () => {}
              )
            }
            const nosatusehat = res?.data?.entry[0]?.resource?.id || ''
            setdatasatusehat({nosatusehat})
            setdata({
              ...data,
              nosatusehat,
            })
            okRef.show(
              {
                message: res?.message || 'Berhasil memvalidasi data',
                title: 'Berhasil',
              },
              () => {},
              () => {}
            )
          } else {
            okRef.show(
              {
                message: res?.message || 'Gagal memvalidasi data',
                title: 'Gagal',
              },
              () => {},
              () => {}
            )
          }
        })
        .catch(err => {
          // console.log('err', err)
          okRef.show(
            {
              message: '[C0] Gagal memvalidasi data',
              title: 'Gagal',
            },
            () => {},
            () => {}
          )
        })
        .finally(() => {
          setloading(false)
        })
    }
    if (ariaLabel === 'UbahProfil' || ariaLabel === 'UbahAkun') {
      newvalue = ariaLabel === 'UbahAkun' ? 2 : 1
      if (Object.keys(datachange).length > 0 && active !== newvalue) {
        setdatachange({})
        return setactive(newvalue)
      } else {
        return setactive(newvalue)
      }
    }
    if (ariaLabel === 'SimpanProfile') {
      const lstvalidimg = [
        'data:image/png;base64,',
        'data:image/jpeg;base64,',
        'data:image/jpg;base64,',
      ]

      var error = false,
        errorMsg = ''
      if (
        (!data['city'] || data['city'] == '-') &&
        (!datachange['city'] || datachange['city'] == 0)
      )
        return okRef.show({message: 'Kota tidak boleh kosong!'})
      if (
        (!data['subdistrict'] || data['subdistrict'] == '-') &&
        (!datachange['subdistrict'] || datachange['subdistrict'] == 0)
      )
        return okRef.show({message: 'Kecamatan tidak boleh kosong!'})
      if (
        (!data['kelurahan'] || data['kelurahan'] == '-') &&
        (!datachange['kelurahan'] || datachange['kelurahan'] == 0)
      )
        return okRef.show({message: 'Kelurahan tidak boleh kosong!'})

      if (active === 2) {
        if (!(datachange?.password?.length > 0)) {
          error = true
          errorMsg = 'Harap Memasukkan Password Sebelum Mengubah Profile!'
        }
        if (changepswd) {
          if (
            !!(datachange['new-password']?.length > 0) &&
            !!(datachange['confirm-new-password']?.length > 0) &&
            !(datachange['new-password'] === datachange['confirm-new-password'])
          ) {
            error = true
            errorMsg =
              'Password baru tidak sesuai dengan konfirmasi password baru!'
          }
          if (!(datachange['confirm-new-password']?.length > 0)) {
            error = true
            errorMsg = 'Konfirmasi password baru tidak boleh kosong!'
          }
          if (!(datachange['new-password']?.length > 0)) {
            error = true
            errorMsg = 'Password baru tidak boleh kosong!'
          }
          if (!(datachange['new-password']?.length >= 6)) {
            error = true
            errorMsg = 'Password baru tidak boleh kurang dari 6 karakter!'
          }
        }
        if (error) {
          return okRef.show(
            {
              message: errorMsg,
            },
            () => {},
            () => {}
          )
        }
      }
      return saveData()
    }
    if (ariaLabel === 'combo-password') {
      setchangepswd(e.target?.checked)
    }
    if (`${ariaLabel}`.includes('view-input-foto')) {
      // Menampilkan tampilan penuh dari gambar
      var imgmodal = document.importNode(
          document.getElementById(`img-${ariaLabel}`.replace('view-input-', ''))
        ),
        headerimageview = document.createElement('div'),
        headerclose = document.createElement('div')
      imgmodal.style.height = '100vh'
      imgmodal.className =
        'flex bg-contain bg-center border-xl hover:bg-contain'
      headerimageview.className =
        'absolute right-5 top-4 text-xl cursor-pointer'
      headerclose.onclick = formRef.current.handleModal
      headerclose.replaceChildren('ⓧ')
      headerimageview.replaceChildren(headerclose)
      formRef.current.handleModal([imgmodal, headerimageview])
    }
    if (`${ariaLabel}`.includes('remove-input-foto')) {
      // Menampilkan Konfirmasi hapus
      formRef.current.yesNoRef.show(
        {message: 'Apakah anda yakin akan menghapus gambar ini?'},
        () => {
          actialfield = `${ariaLabel}`.replace('remove-input-', '')
          setdata({
            ...data,
            [actialfield]: null,
            [`${actialfield}-to-upload`]: null,
          })
          return onChangeData({[actialfield]: 'DELETE'})
        },
        () => {}
      )
    }
    if (`${ariaLabel}`.includes('RemoveFoto')) {
      // Menampilkan Konfirmasi hapus
      formRef.current.yesNoRef.show(
        {message: 'Apakah anda yakin akan menghapus foto ini?'},
        () => {
          actialfield = 'logo'
          setdata({
            ...data,
            [actialfield]: null,
            logotoupload: null,
          })
          return onChangeData({[actialfield]: 'DELETE'})
        },
        () => {}
      )
    }
    if (`${ariaLabel}`.includes('select-dokkode')) {
      const listmodalprops = {
        dokkode: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {uid: id, reg: 'dbdok', a: 'dk13', type: 'dokter'},
          fieldsearch: 'search',
          valuesearch: 'nmDokter',
          placeholdersearch: 'Cari Kode Dokter BPJS',
          title: 'Pilih Kode Dokter BPJS',
          type: 'bpjs',
          selected: data?.dokkode,
          renderItems: props => (
            <Items
              {...props}
              onPick={im => {
                props.onPick(im.kdDokter)
                setdata({
                  ...data,
                  dokkode: im.kdDokter ?? '',
                })

                //sementara
                // setinitialData({...initialData, dokkode: im.kdDokter ?? '' })

                onChangeData({
                  dokkode: im.kdDokter ?? '',
                })
                if (im) {
                  modalRef.close()
                }
              }}
            />
          ),
        },
      }
      const propsmodal = listmodalprops['dokkode'] || {}
      return handleModal(<SearchContent {...propsmodal} />, 'fit')
    }
    if (lstinputimg.includes(ariaLabel)) {
      if (!!window?.ReactNativeWebView?.postMessage) {
        const newlabel = `${ariaLabel}`.replace('input-', '')
        window.ReactNativeWebView.postMessage(`onModalImagePicker:${newlabel}`)
        return e.target?.blur()
      }
    }
    if (`${ariaLabel}` === 'img-logo' && active === 1) {
      const emId = document.getElementById(`${ariaLabel}`)
      if (!!window?.ReactNativeWebView?.postMessage) {
        const newlabel = `${emId.ariaLabel}`.replace('input-', '')
        window.ReactNativeWebView.postMessage(`onModalImagePicker:${newlabel}`)
        return
      }
      return emId.click()
    }
    if (lstselectdata.includes(`${ariaLabel}`)) {
      actialfield = `${ariaLabel}`.replace('select-', '')
      // return onChangeData({[actialfield]: value})
      var lstdata = {province, city, subdistrict, kelurahan},
        newlist = [],
        selectmodal = document.createElement('div'),
        bodyitems = document.createElement('div'),
        headermodal = document.createElement('div'),
        headertitle = document.createElement('div'),
        headerfirst = document.createElement('div'),
        headerclose = document.createElement('div'),
        newfilters = document.createElement('input', {}),
        titlemodal = e.target?.parentNode?.previousElementSibling?.innerText
      newfilters.style.width = '-webkit-fill-available'
      newfilters.ariaLabel = 'Cari ' + toPascalCase(titlemodal)
      newfilters.placeholder = 'Cari ' + toPascalCase(titlemodal)
      newfilters.className = 'mt-2 rounded-xl'
      newfilters.onchange = function (ef) {
        const arrData = ef.target.parentNode?.previousElementSibling?.childNodes
        // console.log('arrData', arrData)
        const lenData = arrData.length
        for (let i = 0; i < lenData; i++) {
          const element = arrData[i]
          if (element.tagName === 'P') {
            if (
              !element.innerText
                ?.toLocaleLowerCase()
                ?.includes(ef.target.value.toLocaleLowerCase())
            ) {
              element.style.display = 'none'
            } else {
              element.removeAttribute('style')
            }
          }
        }
        ef.target.focus()
      }
      selectmodal.className = 'mt-24'
      headermodal.className =
        'absolute px-4 pt-4 pb-2 left-0 right-0 top-0 bg-white text-xl cursor-pointer'
      headerfirst.className = 'flex justify-between'
      headerclose.onclick = formRef.current.handleModal
      headerclose.replaceChildren('ⓧ')
      headertitle.replaceChildren(toPascalCase(titlemodal))
      headerfirst.replaceChildren(...[headertitle, headerclose])
      headermodal.replaceChildren(...[headerfirst, newfilters])
      newlist = lstdata[actialfield]
        .filter((fl, index) => {
          return actialfield === 'city'
            ? fl.idprovince === data[`idprovince`]
            : actialfield === 'subdistrict'
            ? fl.idcity === data[`idcity`]
            : actialfield === 'kelurahan'
            ? fl.idsubdistrict === data[`idsubdistrict`]
            : true
        })
        .map((im, index) => {
          // console.log('im gan', im)
          var item = document.createElement('p'),
            circle = '●',
            active = im.id === data[`id${actialfield}`]
          item.replaceChildren(im.title)
          item.ariaLabel = `select-${actialfield}-${im.id}`
          item.className = 'p-2 mb-4 border rounded-xl'
          item.onclick = e => {
            formRef.current.handleModal(true)
            if (actialfield === 'kelurahan') {
              setdata({
                ...data,
                [`id${actialfield}`]: `${im.id}`,
                [`${actialfield}`]: im.title,
                kodepos: im.kodepos ?? '',
              })
              onChangeData({
                [`${actialfield}`]: `${im.id}`,
                kodepos: im.kodepos ?? '',
              })
            }
            if (actialfield === 'subdistrict') {
              setdata({
                ...data,
                [`id${actialfield}`]: `${im.id}`,
                [`${actialfield}`]: im.title,
                [`idkelurahan`]: '-',
                [`kelurahan`]: '-',
              })
              onChangeData({[`${actialfield}`]: `${im.id}`, kelurahan: '0'})
            }
            // city, subdistrict, province
            if (actialfield === 'city') {
              setdata({
                ...data,
                [`id${actialfield}`]: `${im.id}`,
                [`${actialfield}`]: im.title,
                [`idsubdistrict`]: '-',
                [`subdistrict`]: '-',
                [`idkelurahan`]: null,
                [`kelurahan`]: null,
              })
              onChangeData({
                [`${actialfield}`]: `${im.id}`,
                subdistrict: '0',
                kelurahan: '0',
              })
            }
            if (actialfield === 'province') {
              setdata({
                ...data,
                [`id${actialfield}`]: `${im.id}`,
                [`${actialfield}`]: im.title,
                [`idsubdistrict`]: null,
                [`subdistrict`]: null,
                [`idkelurahan`]: null,
                [`kelurahan`]: null,
                [`idcity`]: '-',
                [`city`]: '-',
              })
              onChangeData({
                [`${actialfield}`]: `${im.id}`,
                city: '0',
                subdistrict: '0',
                kelurahan: '0',
              })
            }
          }
          if (active) {
            item.className = classNames(
              item.className,
              'border-blue-500 text-blue-500'
            )
            item.replaceChildren(`${im.title}`)
          }
          return item
        })
      bodyitems.replaceChildren(...[...newlist])
      formRef.current.handleModal([selectmodal, bodyitems, headermodal])
    }
  }
  const onChangeData = useCallback(
    e => {
      // console.log('e', e)
      setdatachange(t => {
        return Object.assign(t || {}, e)
      })
    },
    // eslint-disable-next-line
    [datachange]
  )
  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actialfield = '',
      newvalue = ''
    // console.log('ariaLabel', ariaLabel, e)
    if (lstchangeddata.includes(`${ariaLabel}`)) {
      actialfield = `${ariaLabel}`.replace('input-', '')
      const lstfielnumber = ['input-nowa']
      newvalue = value
      if (lstfielnumber.includes(`${ariaLabel}`)) {
        newvalue = `${value}`.replace(/[^0-9\.]+/g, '')
        e.target.value = newvalue
      }
      if (ariaLabel === 'input-position') {
        const {lat, lng} = JSON.parse(value)
        var center = {lat, lng}
        data['position'] = JSON.stringify({
          center,
          position: center,
        })
        return onChangeData({
          lat: `${lat}`,
          lng: `${lng}`,
        })
      }
      if (ariaLabel == 'input-kodepos') {
        // Untuk input KodePos, state valuenya diubah juga karena di-bind values.
        newvalue = `${value}`.replace(/[^0-9]/g, '') // replace value non-angka dengan empty string
        setdata({...data, [actialfield]: newvalue})
      }
      return onChangeData({[actialfield]: newvalue})
    }
    if (lstinputimg.includes(ariaLabel)) {
      newvalue = await encodeImageFileAsURL(e.target)
      actialfield = `${ariaLabel}`.replace('input-', '')
      // console.log('newvalue', newvalue)
      setdata({
        ...data,
        [actialfield === 'logo' ? 'logotoupload' : `${actialfield}-to-upload`]:
          newvalue,
      })
      return onChangeData({
        datetime: Date.now(),
        [actialfield]: `${
          data?.username ? data?.username.substring(0, 10) : ''
        }_${actialfield.substring(
          actialfield.length - 3,
          actialfield.length
        )}_${Date.now()}.jpg`,
      })
    }
  }
  const onErrorCapture = e => {
    const {ariaLabel} = e.target
    if (lstimgdata.includes(`${ariaLabel}`)) {
      const actialfield = `${ariaLabel}`.replace('img-', '')
      return setdata({...data, [actialfield]: null})
    }
  }
  useEffect(() => {
    document.title = pageTitle
    if (data.username == '') {
      loadData()

      // Cek Custom BPJS PCare
      cekCustomBpjsPcare()
    }
    return () => {}
  }, [])

  useEffect(() => {
    if (!initialLoading) {
      // console.log('onEffect data gan, loading false', data)
      // Get data kelurahan by idkecamatan
      loadKelurahan()
    } else {
      // console.log('onEffect data gan, loading true', data)
    }
    return () => {}
  }, [initialLoading, data.idsubdistrict])

  const loadKelurahan = () => {
    if (empty(data.idsubdistrict) || data.idsubdistrict == '-')
      return setkelurahan([])

    axios
      .post(
        `${API5}/identitas-klinik/findkelurahan2`,
        QueryString.stringify({
          a: 'default',
          id: data.idsubdistrict,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let kelurahan = res.data.map(im => ({
          title: im.kelurahan,
          id: im.idkelurahan,
          idsubdistrict: im.idkecamatan,
          kodepos: im.kodepos,
        }))
        setkelurahan(kelurahan)
        if (data.idkelurahan) {
          let selectedKelurahanString =
            res?.data.find(fn => fn.idkelurahan === data['idkelurahan'])
              ?.kelurahan || '-'

          if (
            !initialData?.kelurahan ||
            initialData?.kelurahan == initialData?.idkelurahan
          ) {
            setinitialData(old => ({
              ...old,
              kelurahan: selectedKelurahanString,
            }))
          }
          setdata(old => ({...old, kelurahan: selectedKelurahanString}))
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat loadKelurahan')
      })
  }

  const handleMessageRn = (
    e,
    data,
    formRef = {current: {okRef: {show: () => {}}}}
  ) => {
    // console.log('e', e)
    var actialfield = '',
      newvalue = '',
      onerror = false,
      newdata = {}
    const {okRef} = formRef?.current || {okRef: {show: () => {}}}
    try {
      if (`${e?.data}`?.match(/^onChangeImage:/i)) {
        newdata = JSON.parse(e.data.replace(/^onChangeImage:/i, ''))
        actialfield = newdata.field
        newvalue = newdata.value
        setdata({
          ...data,
          [actialfield === 'logo'
            ? 'logotoupload'
            : `${actialfield}-to-upload`]: newvalue,
        })
        return onChangeData({
          datetime: Date.now(),
          [actialfield]: `${
            data?.username ? data?.username.substring(0, 10) : ''
          }_${actialfield.substring(
            actialfield.length - 3,
            actialfield.length
          )}_${Date.now()}.jpg`,
        })
      }
    } catch (error) {
      if (e?.data?.match(/^onChangeImage:/i)) {
        return
      }
      if (onerror) {
        okRef.show(
          {
            message: '[MRN0] Telah terjadi kesalahan!',
          },
          () => {},
          () => {}
        )
      }
    }
  }
  useEffect(() => {
    window.addEventListener('message', e => handleMessageRn(e, data, formRef))
    return () => {
      window.removeEventListener('message', e =>
        handleMessageRn(e, data, formRef)
      )
    }
  }, [data, formRef])

  if (error) {
    return <FullErrorView message={error} />
  }

  if (initialLoading) return <ProgressFullScreen visible />

  return (
    <TemplateForm
      ref={formRef}
      loading={loading}
      onChangeCapture={onChangeCapture}
      onErrorCapture={onErrorCapture}
      onClick={onClick}
    >
      <PrdUbahProfil
        showUbahProfil={active === 1}
        showUbahAkun={active === 2}
        showUbahPassword={changepswd}
        showKodeBpjs={customPcare}
        HeaderType={pageTitle}
        data={data}
      />
    </TemplateForm>
  )
}
