import {VmButton, VmInput, VmText, VmView} from 'components'
import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import VmHeader from 'components/layout/VmHeader'
import {VmFab, VmList, VmPicker} from 'components'
import {
  catatLogDokter,
  empty,
  tglDDMMMYYYY,
  tglDashNoSpace,
  tglYMD,
  tglYMDHMS,
  toCurrency,
  triggerEventBus,
  validateNumericInput,
  triggerEventBusAntrean,
} from 'utils/functions'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {
  Navigate,
  redirect,
  useSearchParams,
  useNavigate,
} from 'react-router-dom'
import {
  VmCalendarDaysIcon,
  VmCheckIcon,
  VmMagnifyingGlassIcon,
  VmPhoneIcon,
  VmPinLocationIcon,
  VmPlusIcon,
  VmTrashIcon,
  VmXMarkIcon,
} from 'components/icons/solid'
import {API, graphqlOperation} from 'aws-amplify'
import {mAktifkanDiagnosa, mDeleteDiagnosa} from 'graphql/mutations'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from 'utils/localstorage'
import {APIBPJS, APIDOC, APISATSET} from 'config/apiurl'
import {pasienDummy, pasienEmpty} from './dummy'
import satusehaticon from '../../assets/satusehat.png'
// import bpjsicon from '../../../public/logo-bpjs-kesehatan.png'
import {VmLDewasaColorIcon} from 'components/icons/avatar'
import classNames from 'classnames'
import {ProgressFullScreen} from 'molecules'
import {decode, encode} from 'utils/encryptions'
import VmLoading from 'components/loader/VmLoading'
import moment from 'moment'

registerLocale('id', id)

const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

function CardPasien({data}) {
  let terhubung_satset = !empty(data.pasid_satusehat)
  return (
    <VmView className="CardPasien flex-1 self-stretch h-56 p-4 bg-white rounded-xl shadow-lg shadow-zinc-300 flex-col justify-start items-start gap-2.5 flex">
      <VmView className="UpperContent self-stretch justify-start items-start gap-2.5 flex">
        <VmView className="Agustus2001 grow shrink basis-0 text-neutral-700 text-sm font-bold">
          {tglDDMMMYYYY(data.pastgllahir)}
        </VmView>

        {terhubung_satset ? (
          <VmView className="SatusehatWrap justify-start items-center gap-2 flex">
            <VmView className="Terhubung text-neutral-700 text-sm font-normal">
              Terhubung
            </VmView>
            <img className="Satusehat1 w-6 h-6" src={satusehaticon} />
          </VmView>
        ) : null}
      </VmView>
      <VmView className="HorizontalWrap self-stretch py-2 border-t border-neutral-400 justify-start items-start gap-3 flex">
        <VmView className="LeftContent flex-col justify-center items-center gap-2.5 flex">
          <VmLDewasaColorIcon className="w-20" />
          <VmView className="Thn text-blue-500 text-sm font-bold">
            {data.pasumur} Thn
          </VmView>
        </VmView>
        <VmView className="RightContent grow shrink basis-0 px-1 py-0.5 rounded-lg flex-col justify-start items-start gap-2 flex">
          <VmView className="RmWrap px-1 py-0.5 bg-blue-500 bg-opacity-10 rounded-lg justify-start items-start gap-2.5 flex">
            <VmView className="Rm2306210001 text-blue-500 text-sm font-bold">
              {data.pasrm}
            </VmView>
          </VmView>
          <VmView className="BpjsWrap justify-start items-center gap-2 flex">
            <img
              className="LogoBpjsKesehatan1 w-4 h-4"
              src={'/logo-bpjs-kesehatan.png'}
            />
            <VmView className=" text-neutral-700 text-sm font-normal">
              {data.pasnobpjs ?? '-'}
            </VmView>
          </VmView>
          <VmView className="IndiraKalistha text-neutral-700 text-sm font-bold">
            {data.pasnama}
          </VmView>
          <VmView className="AlamatWrap justify-start items-center gap-2 flex">
            <VmView className="SemolowaruUtaraRt03Rw04 text-neutral-700 text-sm font-normal flex items-center gap-1">
              <VmPinLocationIcon className="w-4" />
              <VmText>{data.pasalamat ?? '-'}</VmText>
            </VmView>
          </VmView>
          <VmView className="PhoneWrap justify-start items-center gap-2 flex">
            <VmView className="SemolowaruUtaraRt03Rw04 text-red-500 text-sm font-normal flex items-center gap-1">
              <VmPhoneIcon className="w-4" />
              <VmText>{data.pastelp ?? '-'}</VmText>
            </VmView>
          </VmView>
        </VmView>
      </VmView>
    </VmView>
  )
}

function VmInputCaption({value, onChange, title, typeInput = 'text', ...rest}) {
  // console.log('render VmInputCaption', rest)
  return (
    <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
      <VmView className="NamaLengkap text-neutral-700 text-sm font-normal">
        {title}
      </VmView>

      <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
        <VmInput
          {...rest}
          onChange={onChange}
          value={value}
          typeInput={typeInput}
          className="w-full py-1.5"
          {...(typeInput == 'number'
            ? {
                onKeyDown: event => {
                  // Allow special keys like backspace, delete, arrow keys, etc.
                  const allowedSpecialKeys = [8, 9, 37, 39, 46]

                  // Allow numbers
                  const allowedNumbers = Array.from(
                    {length: 10},
                    (_, i) => i + 48
                  )

                  // Check if the pressed key is allowed
                  if (
                    allowedSpecialKeys.includes(event.keyCode) ||
                    allowedNumbers.includes(event.keyCode)
                  ) {
                    return true // Allow input
                  } else {
                    event.preventDefault() // Prevent input
                  }
                },
                onPaste: event => {
                  // Get the pasted text
                  const pastedText = (
                    event.clipboardData || window.clipboardData
                  ).getData('text')

                  // Check if the pasted text contains only numbers
                  if (!/^\d+$/.test(pastedText)) {
                    event.preventDefault() // Prevent paste
                  }
                },
              }
            : {})} // Kalo number, harus koma gk bisa bulat
        />
      </VmView>
    </VmView>
  )
}

export default function Index() {
  const [tglkunjungan, settglkunjungan] = useState(new Date())
  const params = useParams()
  const {id: dctid, nama_lengkap} = params
  const navigate = useNavigate()
  const [loading, setloading] = useState(false)
  const [gettingId, setgettingId] = useState(false)

  // let initial = pasienDummy
  let initial = pasienEmpty

  const [tahun, settahun] = useState(initial.tahun)
  const [bulan, setbulan] = useState(initial.bulan)
  const [hari, sethari] = useState(initial.hari)
  const [statussatusehat, setstatussatusehat] = useState(
    initial.statussatusehat
  )
  const [pasnama, setpasnama] = useState(initial.pasnama)
  const [pasrm, setpasrm] = useState(initial.pasrm)
  const [pasid, setpasid] = useState(initial.pasid)
  const [pasalamat, setpasalamat] = useState(initial.pasalamat)
  const [pasjk, setpasjk] = useState(initial.pasjk)
  const [pastgllahir, setpastgllahir] = useState(initial.pastgllahir)
  const [pasnobpjs, setpasnobpjs] = useState(initial.pasnobpjs)
  const [pasnokk, setpasnokk] = useState(initial.pasnokk)
  const [pasnoktp, setpasnoktp] = useState(initial.pasnoktp)
  const [pasgoldarah, setpasgoldarah] = useState(initial.pasgoldarah)
  const [penanggungjawabPrev, setpenanggungjawabPrev] = useState({})

  const [provinsi, setprovinsi] = useState(initial.provinsi)
  const [kota, setkota] = useState(initial.kota)
  const [kecamatan, setkecamatan] = useState(initial.kecamatan)
  const [agid, setagid] = useState(initial.agama.id)
  const [pendid, setpendid] = useState(initial.pendidikan.id)
  const [pasidkec, setpasidkec] = useState(initial.kecamatan.id)
  const [pasid_satusehat, setpasid_satusehat] = useState(
    initial.pasid_satusehat
  )
  const [pasconsent, setpasconsent] = useState(initial.pasconsent)
  const [pasumur, setpasumur] = useState(initial.pasumur)
  const [agnama, setagnama] = useState(initial.agnama)
  const [pendnama, setpendnama] = useState(initial.pendnama)
  const [pastelp, setpastelp] = useState(initial.pastelp)
  const [paspekerjaan, setpaspekerjaan] = useState(initial.paspekerjaan)
  const [pasemail, setpasemail] = useState(initial.pasemail)
  const [pasalergi, setpasalergi] = useState(initial.pasalergi)
  const [pasalergimakanan, setpasalergimakanan] = useState(
    initial.pasalergimakanan
  )

  // Data Penanggungjawab pasien
  const [penid, setpenid] = useState(0)
  const [pennama, setpennama] = useState(initial.pennama)
  const [pennik, setpennik] = useState(initial.pennik)
  const [penhubungan, setpenhubungan] = useState(initial.penhubungan)
  const [penjk, setpenjk] = useState(initial.penjk)
  const [pentelp, setpentelp] = useState(initial.pentelp)
  const [penalamat, setpenalamat] = useState(initial.penalamat)
  const [penpasienpenanggungjawab, setpenpasienpenanggungjawab] = useState('')

  const [kunid, setkunid] = useState(0)
  /**
   * registrasi_pasien = awal registrasi pasien
   * finish_registrasi = setelah Create registrasi pasien
   */
  const [state, setstate] = useState('registrasi_pasien')

  const [identitas, setidentitas] = useState({})
  const [consent1, setconsent1] = useState(initial.consent1),
    [consent2, setconsent2] = useState(initial.consent2),
    [consent3, setconsent3] = useState(initial.consent3)

  /**
   * State untuk Custom BPJS PCare
   */
  const [keluhanutama, setkeluhanutama] = useState('')
  const [customPcare, setCustomPcare] = useState(!true)
  const [kdProvider, setkdProvider] = useState('')
  const [pasienPcare, setPasienPcare] = useState({
    jeniskunjungan: 1,
    perawatan: 10,
    keluhan: '',
    tb: '',
    bb: '',
    lingkar: '',
    sistole: '',
    diastole: '',
    resprate: '',
    heartrate: '',
    entry: 1,
    entryeditable: true,
    kdProvider: '',
    nmProvider: '',
    hubunganKeluarga: '',
    kdPoli: '001',
    listPoli: [],
  })

  const alertok = useRef(null)
  useEffect(() => {
    // get LocalStorage
    // let locStorageData = getLclStorage('regpasien_data', {type: 2})
    let params_pasid = getLclStorage('regpasien_pasid', {type: 2})

    if (!empty(params_pasid)) {
      let pasid = getLclStorage('regpasien_pasid', {type: 2})
      let pasnobpjs = getLclStorage('regpasien_pasnobpjs', {type: 2})
      let pasnokk = getLclStorage('regpasien_pasnokk', {type: 2})
      let pasnama = getLclStorage('regpasien_pasnama', {type: 2})
      let pasgoldarah = getLclStorage('regpasien_pasgoldarah', {type: 2})
      let pasjk = getLclStorage('regpasien_pasjk', {type: 2})
      let pastgllahir = getLclStorage('regpasien_pastgllahir', {type: 2})
      let pasalamat = getLclStorage('regpasien_pasalamat', {type: 2})
      let pasidkec = getLclStorage('regpasien_pasidkec', {type: 2})
      let pastelp = getLclStorage('regpasien_pastelp', {type: 2})
      let paspekerjaan = getLclStorage('regpasien_paspekerjaan', {type: 2})
      let pasemail = getLclStorage('regpasien_pasemail', {type: 2})
      let pasalergi = getLclStorage('regpasien_pasalergi', {type: 2})
      let pasalergimakanan = getLclStorage('regpasien_pasalergimakanan', {
        type: 2,
      })
      let pasnoktp = getLclStorage('regpasien_pasnoktp', {type: 2})
      let pasrm = getLclStorage('regpasien_pasrm', {type: 2})
      let pasid_satusehat = getLclStorage('regpasien_pasid_satusehat', {
        type: 2,
      })
      let pasconsent = getLclStorage('regpasien_pasconsent', {type: 2})
      let pasumur = getLclStorage('regpasien_pasumur', {type: 2})
      let agid = getLclStorage('regpasien_agid', {type: 2})
      let pendid = getLclStorage('regpasien_pendid', {type: 2})

      let penid = getLclStorage('regpasien_penid', {type: 2})
      let pennama = getLclStorage('regpasien_pennama', {type: 2})
      let pennik = getLclStorage('regpasien_pennik', {type: 2})
      let penhubungan = getLclStorage('regpasien_penhubungan', {type: 2})
      let penjk = getLclStorage('regpasien_penjk', {type: 2})
      let pentelp = getLclStorage('regpasien_pentelp', {type: 2})
      let penalamat = getLclStorage('regpasien_penalamat', {type: 2})

      let encodedDate = encode(tglYMD(new Date()))
      let decodedDate = decode(encodedDate)
      // console.log('useEffect - localStorage', {
      //   pasid,
      //   pasnobpjs,
      //   pasnokk,
      //   pasnama,
      //   pasgoldarah,
      //   pasjk,
      //   pastgllahir,
      //   encodedDate,
      //   decodedDate,
      //   pasalamat,
      //   pasidkec,
      //   pastelp,
      //   paspekerjaan,
      //   pasemail,
      //   pasalergi,
      //   pasalergimakanan,
      //   pasnoktp,
      //   pasrm,
      //   pasid_satusehat,
      //   pasconsent,
      //   pasumur,
      //   agid,
      //   pendid,
      //   penid,
      //   pennama,
      //   pennik,
      //   penhubungan,
      //   penjk,
      //   pentelp,
      //   penalamat,
      // })

      setpasid(pasid)
      setpasnobpjs(pasnobpjs)
      setpasnokk(pasnokk)
      setpasnama(pasnama)
      setpasgoldarah(pasgoldarah)
      setpasjk(pasjk)
      setpastgllahir(pastgllahir)
      setpasalamat(pasalamat)
      setpasidkec(pasidkec)
      setpastelp(pastelp)
      setpaspekerjaan(paspekerjaan)
      setpasemail(pasemail)
      setpasalergi(pasalergi)
      setpasalergimakanan(pasalergimakanan)
      setpasnoktp(pasnoktp)
      setpasrm(pasrm)
      setpasid_satusehat(pasid_satusehat)
      setpasconsent(pasconsent)

      if (pasid_satusehat?.length > 0) {
        setstatussatusehat(pasid_satusehat?.length > 0)
      }

      setconsent1(pasconsent == 1)
      setconsent2(pasconsent == 1)
      setconsent3(pasconsent == 1)

      setpasumur(pasumur)
      setagid(agid)
      setpendid(pendid)
      setpennama(pennama)

      // Set data penanggungjawab pasien
      setpenanggungjawabPrev({
        penid,
        pennik,
        penhubungan,
        penjk,
        pentelp,
        penalamat,
      })
      setpenid(penid)
      setpennik(pennik)
      setpenhubungan(penhubungan)
      setpenjk(penjk)
      setpentelp(pentelp)
      setpenalamat(penalamat)

      // Reset data custom PCare
      setPasienPcare({
        ...pasienPcare,
        jeniskunjungan: 1,
        perawatan: 10,
        keluhan: '',
        tb: '',
        bb: '',
        lingkar: '',
        sistole: '',
        diastole: '',
        resprate: '',
        heartrate: '',
        entry: !empty(pasnobpjs) ? 1 : 2,
        entryeditable: !empty(pasnobpjs),
        kdProvider: '',
        nmProvider: '',
        hubunganKeluarga: '',
        kdPoli: '001',
      })
    }

    return () => {}
  }, [])

  useEffect(() => {
    console.log('useEffect listPoli', {
      length: pasienPcare.listPoli.length,
      data: pasienPcare.listPoli,
    })
    return () => {}
  }, [pasienPcare.listPoli.length])

  useEffect(() => {
    if (!empty(dctid)) {
      getDataUser()

      // Cek data custom user
      cekCustomBpjsPcare()
    }
    return () => {}
  }, [dctid])

  useEffect(() => {
    if (customPcare) {
      // jika punya custom PCare
      if (pasid && pasnobpjs) {
        // User sudah pilih pasien & punya No. BPJS, maka get data pasien BPJS-nya
        cekPasienPcare('bpjs', false)
      }
    }

    return () => {}
  }, [customPcare, pasid, pasnobpjs])

  useEffect(() => {
    // console.log('useEffect pastgllahir', pastgllahir)
    // Calculate usia setelah mengubah tgl lahir
    let current = moment()
    let tgllahir = moment(pastgllahir)
    let tahun = current.diff(tgllahir, 'years')
    current.subtract(tahun, 'years')

    let bulan = current.diff(tgllahir, 'months')
    current.subtract(bulan, 'months')

    let hari = current.diff(tgllahir, 'days')
    settahun(tahun.toString())
    setbulan(bulan.toString())
    sethari(hari.toString())
    return () => {}
  }, [pastgllahir])

  const cekCustomBpjsPcare = async _ => {
    const uid = dctid
    axios
      .post(
        `${APIDOC}/backend-data-registrasi/get-custom`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('cekCustomBpjsPcare', res)
        if (res.data) {
          if (res.data['121']) {
            // Berarti punya custom
            setCustomPcare(true)

            // Juga Get data Poli dari BPJS
            getPoliBpjs()
          } else {
            // Tidak punya custom
            setPasienPcare(pasienPcare => ({
              ...pasienPcare,
              entry: 2,
              entryeditable: false,
            }))
          }
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Cek Custom BPJS PCare')
      })
  }

  /**
   *
   * @param {*} type bpjs | nik
   * @returns
   */
  const cekPasienPcare = (type, replaceValue = true) => {
    setloading(true)
    let valueToCheck,
      caption,
      stateToUpdate = '',
      validityStateToUpdate = ''
    switch (type) {
      case 'bpjs':
        valueToCheck = pasnobpjs
        caption = 'No. BPJS'
        stateToUpdate = 'checkingPasienPcareByBpjs'
        validityStateToUpdate = 'pasienPcareValidByBpjs'
        break

      case 'nik':
        valueToCheck = pasnoktp
        caption = 'No. NIK'
        stateToUpdate = 'checkingPasienPcareByNik'
        validityStateToUpdate = 'pasienPcareValidByNik'
        break
    }
    if (!valueToCheck) {
      alertok.current?.show({message: `Harap isi ${caption}`})
      return
    }

    const uid = dctid
    // setCheckingState({...checkingState, [stateToUpdate]: true})
    let tanggalkunjungan = getLclStorage('regpasien_tanggalkunjungan', {type: 2}) || new Date()

    let params = {
      a: 'dk13',
      // a: 'v31d8',
      tglPelayanan: tglYMD(tanggalkunjungan),
      noKartu: valueToCheck,
      type, // bpjs / nik
      services: 'pcare', // pcare / vclaim
      uid,
    }

    console.log('cekPasienPcare - params', params)

    // let check = {
    //   response: {
    //     noKartu: '0002035324607',
    //     nama: 'Riri Fajriwati',
    //     hubunganKeluarga: 'Peserta',
    //     sex: 'P',
    //     tglLahir: '15-09-1996',
    //     tglMulaiAktif: '01-03-2016',
    //     tglAkhirBerlaku: '31-12-2050',
    //     kdProviderPst: {
    //       kdProvider: '01150111',
    //       nmProvider: 'KEC. GROGOL PETAMBURAN',
    //     },
    //     kdProviderGigi: {
    //       kdProvider: null,
    //       nmProvider: null,
    //     },
    //     jnsKelas: {
    //       nama: 'KELAS II',
    //       kode: '2',
    //     },
    //     jnsPeserta: {
    //       nama: 'PEGAWAI SWASTA',
    //       kode: '13',
    //     },
    //     golDarah: '0',
    //     noHP: '',
    //     noKTP: '3211035509960001',
    //     pstProl: null,
    //     pstPrb: null,
    //     aktif: true,
    //     ketAktif: 'AKTIF',
    //     asuransi: {
    //       kdAsuransi: null,
    //       nmAsuransi: null,
    //       noAsuransi: null,
    //       cob: false,
    //     },
    //     tunggakan: 0,
    //   },
    //   metaData: {
    //     message: 'OK',
    //     code: 200,
    //   },
    //   log: 'berhasil',
    //   header: [
    //     'Content-Type: application/json',
    //     'Accept: application/json',
    //     'x-cons-id: 19155',
    //     'x-timestamp: 1709642944',
    //     'x-signature: D06rUfeFCuqV6UDqt3Zw6PoD1DDaNUqEBHtl9JmUxF4=',
    //     'x-authorization: Basic a2xpbmlrX2FhbmlzYWg6UXdlcnR5MSE6MDk1',
    //     'user_key: d5a838baaacb7ff98e9f89e1bfebd896',
    //   ],
    //   status: 1,
    //   message: 'berhasil',
    // }
    // let base = 'https://apibpjs.vmedis.com:7777' // Coba cek data pasien bisa pake master biar ga kena limit
    let base = APIBPJS
    return axios
      .post(`${base}/bpjs-jkn/get-pasien-bpjs`, QueryString.stringify(params))
      .then(({data: check}) => {
        // Berhasil Get Data
        let metadata = check['metaData']?.code
        let peserta = check['response']
        let pasienAktif = peserta?.['ketAktif']

        if (metadata == '200' && pasienAktif == 'AKTIF') {
          console.log('cekPasienPcare - pasien aktif', peserta)
          let keluhanutama = getLclStorage('regpasien_keluhan', {type: 2}) || ''
          let tanggalkunjungan = getLclStorage('regpasien_tanggalkunjungan', {type: 2}) || new Date()

          console.error(peserta?.kdProviderPst?.kdProvider, 'kdProviderPst')
          setkdProvider(peserta?.kdProviderPst?.kdProvider)
          
          setPasienPcare(pasienPcare => ({
            ...pasienPcare,
            kdProvider: peserta?.kdProviderPst.kdProvider,
            nmProvider: peserta?.kdProviderPst.nmProvider,
            hubunganKeluarga: peserta?.hubunganKeluarga,
            keluhan: keluhanutama
          }))

          setkeluhanutama(keluhanutama)
          settglkunjungan(new Date(tanggalkunjungan))

          // alertok.current?.show({message: 'Pasien aktif'})

          // setValidityState({...validityState, [validityStateToUpdate]: true})

          // Tetap Replace Value NIK & No. BPJS terlepas replaceVlaue = true/false
          setpasnoktp(peserta?.noKTP)
          setpasnobpjs(peserta?.noKartu)

          if (replaceValue) {
            // Auto Fill Field jika replaceValue=true
            let sex = peserta['sex'] == 'L' ? 'Laki-laki' : 'Perempuan'
            let reversed = peserta?.tglLahir?.split('-').reverse().join('-')

            setpasnama(peserta?.nama)

            setpaspekerjaan(peserta?.['jnsPeserta']['nama'])
            setpasgoldarah(!empty(peserta?.golDarah) ? peserta?.golDarah : '')
            setpastelp(peserta?.noHP)
            setpasjk(sex)
            setpastgllahir(new Date(reversed))
          }
          setloading(false)
          return {status: 1, message: 'Pasien aktif'}
        } else {
          let message
          if (!empty(pasienAktif) && pasienAktif != 'AKTIF') {
            message = '[CPC01] Pasien BPJS tidak aktif : ' + pasienAktif
            // alertok.current?.show({
            //   message,
            // })
          } else {
            message = '[CPC02] Pasien BPJS tidak ditemukan'
            // alertok.current?.show({
            //   message,
            // })
          }

          // Set pasien tidak valid
          // setValidityState({...validityState, [validityStateToUpdate]: false})
          setloading(false)
          return {status: 0, message}
        }
      })
      .catch(e => {
        let message = '[CPC03] Terjadi Kesalahan saat cekPasienPcare'
        console.log(message, e.response)
        alertok.current?.show({
          message,
        })
        // Set pasien tidak valid
        // setValidityState({...validityState, [validityStateToUpdate]: false})
        setloading(false)
        return {status: 0, message}
      })
      .finally(() => {
        setloading(false)
        // setCheckingState({...checkingState, [stateToUpdate]: false})
      })
  }

  const getPoliBpjs = () => {
    const uid = dctid

    // let res = {
    //   response: {
    //     count: 16,
    //     list: [
    //       {
    //         kdPoli: '001',
    //         nmPoli: 'POLI UMUM',
    //         poliSakit: true,
    //       },
    //       {
    //         kdPoli: '002',
    //         nmPoli: 'POLI GIGI & MULUT',
    //         poliSakit: true,
    //       },
    //       {
    //         kdPoli: '003',
    //         nmPoli: 'POLI KIA',
    //         poliSakit: true,
    //       },
    //       {
    //         kdPoli: '008',
    //         nmPoli: 'POLI KB',
    //         poliSakit: true,
    //       },
    //       {
    //         kdPoli: '020',
    //         nmPoli: 'Home-Visit',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '021',
    //         nmPoli: 'Konseling',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '023',
    //         nmPoli: 'Imunisasi (BCG)',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '024',
    //         nmPoli: 'Imunisasi (DPT)',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '025',
    //         nmPoli: 'Imunisasi (Polio)',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '026',
    //         nmPoli: 'Imunisasi (Campak)',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '027',
    //         nmPoli: 'Imunisasi (Hep. B)',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '034',
    //         nmPoli: 'Edukasi-Kelompok Prolanis',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '035',
    //         nmPoli: 'Senam-Kelompok Prolanis',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '036',
    //         nmPoli: 'Edukasi-Kelompok Prolanis testing',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '999',
    //         nmPoli: 'Kunjungan Online',
    //         poliSakit: false,
    //       },
    //       {
    //         kdPoli: '998',
    //         nmPoli: 'Kunjungan Online',
    //         poliSakit: true,
    //       },
    //     ],
    //   },
    //   metaData: {
    //     message: 'OK',
    //     code: 200,
    //   },
    //   log: 'berhasil',
    //   header: [
    //     'Content-Type: application/json',
    //     'Accept: application/json',
    //     'x-cons-id: 19155',
    //     'x-timestamp: 1709006429',
    //     'x-signature: gfLM4U0bDTwa6opv7bmeS79F5vgBPq/oKWyQj1uArDU=',
    //     'x-authorization: Basic a2xpbmlrX2FhbmlzYWg6UXdlcnR5MSE6MDk1',
    //     'user_key: d5a838baaacb7ff98e9f89e1bfebd896',
    //   ],
    //   status: 1,
    //   message: 'berhasil',
    // }
    // let base = 'https://apibpjs.vmedis.com:7777' // Coba cek data pasien bisa pake master biar ga kena limit
    let base = APIBPJS
    axios
      .post(
        `${base}/bpjs-jkn/get-data-pcare`,
        QueryString.stringify({
          // a: 'v31d8',
          a: 'dk13',
          url: 'poli/fktp/1/100',
          services: 'pcare',
          uid,
        })
      )
      .then(({data: res}) => {
        let datapoli = res.response?.list
        if (datapoli) {
          let listPoli = datapoli.map(pol => {
            return {text: pol.nmPoli, value: pol.kdPoli}
          })
          console.log('getPoliBpjs', listPoli)
          // Berhasil Get Data
          setPasienPcare({
            ...pasienPcare,
            listPoli,
          })
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat getPoliBpjs')
      })
  }

  const getIdPasienSatuSehat = _ => {
    if (empty(pasnama) || empty(pasnoktp) || empty(pastgllahir)) {
      alertok.current?.show({
        message:
          'Harap isi Nama Pasien, NIK, dan Tangal Lahir untuk mencari ID SatuSehat Pasien',
      })
      return
    }

    setgettingId(true)
    return axios
      .post(
        `${APISATSET}/satusehat-pasien/cari-id-satusehat-v2`,
        QueryString.stringify({
          reg: 'dbdok',
          a: 'dk13',
          kl_id: '19',
          userid: dctid,
          pasnoktp,
          pasnama,
          pastgllahir: tglYMD(pastgllahir),
        })
      )
      .then(res => {
        const result = res?.data
        // Berhasil Get Data
        if (result?.status == 1) {
          if (result.data.total > 0) {
            setpasid_satusehat(result.data.entry[0].resource.id)
          } else {
            alertok.current?.show({
              message:
                'Pasien dengan NIK ' +
                pasnoktp +
                ', Nama ' +
                pasnama +
                ', Tanggal Lahir ' +
                moment(pastgllahir).format('YYYY-MM-DD') +
                ' tidak ditemukan di SATUSEHAT',
            })
            setpasid_satusehat('')
          }
        } else {
          setpasid_satusehat('')
          alertok.current?.show({
            message: result?.message || 'Gagal menghubungkan ke SATUSEHAT',
          })
        }
      })
      .catch(e => {
        alertok.current?.show({
          message: 'Terjadi kesalahan saat get ID SatuSehat.',
        })
        // console.warn(
        //   'Terjadi Kesalahan saat get ID Pasien SatuSehat.',
        //   e.response?.data ?? e.message
        // )
      })
      .finally(() => {
        setgettingId(!true)
      })
  }

  const validate = () => {
    const {
      entry,
      tglkunjungan,
      jeniskunjungan,
      perawatan,
      keluhan,
      tb,
      bb,
      lingkar,
      sistole,
      diastole,
      resprate,
      heartrate,
      // kdProvider,
      nmProvider,
      hubunganKeluarga,
    } = pasienPcare
    if (!sudahPilihPasien) {
      alertok.current?.show({message: 'Harap memilih pasien terlebih dahulu.'})
      return
    }

    setloading(true)
    if (customPcare && entry == 1) {
      // Cek validitas data pasien
      console.log('validate - value kdProvider', kdProvider)
      if (!kdProvider) {
        // Pasien tidak terdaftar
        alertok.current?.show({
          message:
            '[RGP03] Data No. BPJS pasien tidak valid, silakan update data pasien terlebih dahulu.',
        })
        setloading(false)
        return
      }

      // Jika punya custom pcare, dan entri data pcarenya dibuat aktif, maka submit data pcare ke API BPJS dulu.
      let poldefault = '001'
      let dataPcare = {
        kdProviderPeserta: kdProvider,
        tglDaftar: tglDashNoSpace(tglkunjungan),
        noKartu: pasnobpjs,
        kdPoli: pasienPcare.kdPoli,
        keluhan: keluhan || keluhanutama,
        kunjSakit: jeniskunjungan,
        sistole: sistole,
        diastole: diastole,
        beratBadan: bb,
        tinggiBadan: tb,
        respRate: resprate,
        lingkarPerut: lingkar,
        heartRate: heartrate,
        rujukBalik: 0,
        kdTkp: perawatan,
      }

      let nokunjungan = getLclStorage('regpasien_nokunjungan', {type: 2}) || ''

      let params_add_antrean_fktp = {
        appid: 'dk13',
        reg: 'dbdok',
        uid: dctid,
        nomorkartu: pasnobpjs ?? '',
        nik: pasnoktp ?? '',
        nohp: pastelp ?? '',
        // kodepoli: pasienPcare.kdPoli,
        // namapoli: 'POLI UMUM',
        norm: pasrm ?? '',
        tanggalperiksa: tglYMD(tglkunjungan),
        // kodedokter: '',
        // namadokter: '',
        jampraktek: moment().format('HH:mm'),
        // nomorantrean: '001',
        // angkaantrean: 1,
        keterangan: `Tambah Antrean Pasien ${pasnobpjs ?? ''}`,
      };

      let params_antrean_fktp = {
        appid: 'dk13',
        reg: 'dbdok',
        uid: dctid,
        tanggalperiksa: tglYMD(tglkunjungan),
        jampraktek: moment().format('HH:mm'),
        kodepoli: pasienPcare?.kdPoli,
        nomorkartu: pasnobpjs,
        status: 1
      }

      let url_antrean = !nokunjungan?.includes("JKN") ? 'prd-antrean/add-antrean-fktp' : 'prd-antrean/panggil-antrean-fktp'
      let params_antrean = !nokunjungan?.includes("JKN") ? params_add_antrean_fktp : params_antrean_fktp

      axios.post(
        `${APIBPJS}/${url_antrean}`,
        QueryString.stringify(params_antrean)
      )
      .then(({data:resantrean}) => {
        // console.error(resantrean,'resantrean')
        if(resantrean?.status == 204){
          let messageantrean = resantrean?.message ? resantrean?.message : 'Gagal menambahkan Antrean Pasien'
          alertok.current?.show({message : messageantrean})
          setloading(false)
          return false
        }

        axios
          .post(
            `${APIBPJS}/bpjs-jkn/add-pendaftaran-pcare`,
            QueryString.stringify({
              a: 'dk13',
              data: dataPcare,
              uid: dctid,
            })
          )
          .then(({data: resDaftarPcare}) => {
            if (resDaftarPcare['metaData']['message'] == 'CREATED') {
              let kunpcareno = resDaftarPcare['response']['message']
              Promise.all([updatePasien(), buatKunjungan(kunpcareno)]).then(
                res => {
                  setloading(false)
                  clearLocalStorage()
                }
              )
            } else {
              let message = resDaftarPcare?.response?.message ? `${resDaftarPcare?.response?.field} ${resDaftarPcare?.response?.message}`: ''
              let metadataMessage = resDaftarPcare?.metaData && resDaftarPcare?.metaData?.message ? resDaftarPcare?.metaData?.message : ''
              if (!message && metadataMessage && metadataMessage != 'NO_CONTENT') {
                message = metadataMessage
              }
              message = message ? `[BPJS] ${message}` : `[RGP02] Gagal menambahkan Data Pendaftaran PCare dengan No. Kartu ${pasnobpjs}`

              console.log('validate - [RGP02]', resDaftarPcare)
              alertok.current?.show({message})
              setloading(false)
              return
            }
          })
          .catch(e => {
            console.log('validate - [RGP01]', e.response?.data)
            alertok.current?.show({
              message: '[RGP01] Terjadi Kesalahan entri data PCare',
            })
            setloading(false)
          })
      })
      .catch(e => {
        console.error('error', e)
        setloading(false)
        
      })
    } else {
      // Tidak punya custom / punya custom tapi tidak entri data PCare, maka langsung update pasien & create kunjungan saja.
      Promise.all([updatePasien(), buatKunjungan()]).then(res => {
        setloading(false)
        // clear LocalStorage
        clearLocalStorage()
      })
    }
  }

  const clearLocalStorage = () => {
    // Remove localStorage ke Default
    removeLclStorage('regpasien_keluhan', {type: 2})
    removeLclStorage('regpasien_tanggalkunjungan', {type: 2})
    removeLclStorage('regpasien_nokunjungan', {type: 2})
    removeLclStorage('regpasien_pasid', {type: 2})
    removeLclStorage('regpasien_pasnobpjs', {type: 2})
    removeLclStorage('regpasien_pasnokk', {type: 2})
    removeLclStorage('regpasien_pasnama', {type: 2})
    removeLclStorage('regpasien_pasgoldarah', {type: 2})
    removeLclStorage('regpasien_pasjk', {type: 2})
    removeLclStorage('regpasien_pastgllahir', {type: 2})
    removeLclStorage('regpasien_pasalamat', {type: 2})
    removeLclStorage('regpasien_pasidkec', {type: 2})
    removeLclStorage('regpasien_pastelp', {type: 2})
    removeLclStorage('regpasien_paspekerjaan', {type: 2})
    removeLclStorage('regpasien_pasemail', {type: 2})
    removeLclStorage('regpasien_pasalergi', {type: 2})
    removeLclStorage('regpasien_pasalergimakanan', {type: 2})
    removeLclStorage('regpasien_pasnoktp', {type: 2})
    removeLclStorage('regpasien_pasrm', {type: 2})
    removeLclStorage('regpasien_pasid_satusehat', {type: 2})
    removeLclStorage('regpasien_pasconsent', {type: 2})
    removeLclStorage('regpasien_pasumur', {type: 2})
    removeLclStorage('regpasien_agid', {type: 2})
    removeLclStorage('regpasien_pendid', {type: 2})
    removeLclStorage('regpasien_pennama', {type: 2})
    removeLclStorage('regpasien_pennik', {type: 2})
    removeLclStorage('regpasien_penhubungan', {type: 2})
    removeLclStorage('regpasien_penjk', {type: 2})
    removeLclStorage('regpasien_pentelp', {type: 2})
    removeLclStorage('regpasien_penalamat', {type: 2})
  }

  const getDataUser = () => {
    axios
      .post(
        `${APIDOC}/ap-user-dokter/view?id=${dctid}`,
        QueryString.stringify({
          a: 'dk13',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        setidentitas(res.data)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get Identitas User Dokter.')
      })
  }

  const renderContent = () => {
    return (
      <>
        <VmView className="w-full justify-start items-start gap-8 flex flex-col lg:flex-row lg:gap-6">
          <CardPasien
            data={{
              pasnama,
              pasumur,
              pasrm,
              pastelp,
              pasjk,
              pasnobpjs,
              pasalamat,
              pasid_satusehat,
            }}
          />
          {!statussatusehat && (
            <VmView className="flex flex-1 flex-col gap-2 CardPasien p-4 bg-white rounded-xl shadow-lg shadow-zinc-300 pb-5 ">
              <VmView className="SatusehatWrap justify-start items-center gap-2 flex">
                <img className="Satusehat1 w-11 h-11" src={satusehaticon} />
                <VmView className="text-neutral-700 text-sm font-normal">
                  {`Pasien Belum Terhubung SATUSEHAT`}
                  <br />
                  {`Silakan Lengkapi Data Berikut :`}
                </VmView>
              </VmView>
              <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
                <VmView className="Nik text-neutral-700 text-sm font-normal">
                  NIK
                </VmView>
                <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                  <VmInput
                    onKeyDown={validateNumericInput}
                    onChange={e => setpasnoktp(e.target.value)}
                    value={pasnoktp}
                    typeInput={'text'}
                    className="w-full py-1.5"
                  />
                </VmView>
              </VmView>
              <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
                <VmView className="NamaPasien text-neutral-700 text-sm font-normal">
                  Nama Pasien
                </VmView>
                <VmInput
                  onChange={e => setpasnama(e.target.value)}
                  value={pasnama}
                  typeInput={'text'}
                  className="w-full py-1.5"
                />
              </VmView>
              <VmView className="self-stretch flex-col justify-center items-start gap-2 flex">
                <VmView className="text-neutral-700 text-sm font-normal">
                  Tanggal Lahir
                </VmView>
                <VmView className="w-full">
                  <DatePicker
                    locale={'id'}
                    selected={new Date(pastgllahir)}
                    onChange={date => {
                      setpastgllahir(date)
                    }}
                    className="text-xs w-full"
                    wrapperClassName="w-full"
                    withPortal
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={60}
                    scrollableYearDropdown
                    customInput={
                      <VmView className="w-full p-3 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 inline-flex">
                        <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                        <VmView className="text-neutral-700 text-sm font-medium">
                          {tglDDMMMYYYY(pastgllahir)}
                        </VmView>
                      </VmView>
                    }
                    maxDate={new Date()}
                    minDate={new Date(1800, 0, 1)}
                    dropdownMode="select"
                  >
                    <VmView />
                  </DatePicker>
                </VmView>
              </VmView>
              <VmView className="UmurWrap self-stretch flex-col justify-start items-start gap-2 flex">
                <VmView className="Umur text-black text-sm font-normal">
                  Umur
                </VmView>
                <VmView className="TahunBulanHariWrap self-stretch justify-center items-center gap-6 flex">
                  <VmView className="TextinputGroup grow shrink basis-0 justify-start items-center gap-2 flex">
                    <VmInput
                      value={tahun}
                      onChange={e => {
                        let val = e.target.value
                        // console.log(val,typeof val)
                        settahun(val)
                      }}
                      typeInput={'number'}
                      className="w-full py-1.5"
                      min={'0'}
                    />
                    <VmView className="Tahun text-neutral-700 text-sm font-normal">
                      Tahun
                    </VmView>
                  </VmView>
                  <VmView className="TextinputGroup grow shrink basis-0 justify-start items-center gap-2 flex">
                    <VmInput
                      value={bulan}
                      typeInput={'number'}
                      className="w-full py-1.5"
                      onChange={e => {
                        let val = e.target.value
                        // console.log(val,typeof val)
                        setbulan(val)
                      }}
                      min={'0'}
                    />
                    <VmView className="Bulan text-neutral-700 text-sm font-normal">
                      Bulan
                    </VmView>
                  </VmView>
                  <VmView className="TextinputGroup grow shrink basis-0 justify-start items-center gap-2 flex">
                    <VmInput
                      value={hari}
                      typeInput={'number'}
                      className="w-full py-1.5"
                      onChange={e => {
                        let val = e.target.value
                        // console.log(val,typeof val)
                        sethari(val)
                      }}
                      min={'0'}
                    />
                    <VmView className="Hari text-neutral-700 text-sm font-normal">
                      Hari
                    </VmView>
                  </VmView>
                </VmView>
              </VmView>
              <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
                <VmView className="IdSatuSehat text-neutral-700 text-sm font-normal">
                  ID SATUSEHAT
                </VmView>
                <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                  <VmInput
                    value={pasid_satusehat}
                    readonly
                    typeInput={'text'}
                    className="w-full py-1.5"
                  />
                  <VmView
                    onClick={e => {
                      getIdPasienSatuSehat()
                    }}
                    className="IconsearchWrap p-2.5 bg-blue-500 rounded-xl justify-center items-center gap-2.5 flex cursor-pointer"
                  >
                    <VmView className="Cari text-white text-sm font-normal flex items-center gap-2">
                      <VmText>Cari</VmText>
                      {gettingId ? <VmLoading size="xsmall" /> : null}
                    </VmView>
                  </VmView>
                </VmView>
              </VmView>
            </VmView>
          )}
        </VmView>

        {/* Field pasien PCare */}
        {renderFieldPcare()}

        {/* Field PenanggungJawab */}
        <VmView className="OuterWrapperLabelPenanggungjawab flex-col justify-start items-start gap-2.5 flex gap-3">
          <VmView className="LabelPenanggungjawab self-stretch justify-start items-start gap-2.5 flex">
            <VmView className="PenanggungJawabDariPasien grow shrink basis-0 text-neutral-700 text-xl font-normal">
              Penanggung Jawab dari Pasien
            </VmView>
          </VmView>
        </VmView>

        <VmView className="ColContaine rbg-white rounded-xl shadow-lg shadow-zinc-300 justify-start items-start gap-6 flex p-4">
          <VmView className="Right grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
            <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
              <VmView className="NamaLengkap text-neutral-700 text-sm font-normal">
                Nama Lengkap
              </VmView>

              <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                <VmInput
                  onChange={e => setpennama(e.target.value)}
                  value={pennama}
                  typeInput={'text'}
                  className="w-full py-1.5"
                />
              </VmView>
            </VmView>
            <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
              <VmView className="Nik text-neutral-700 text-sm font-normal">
                NIK
              </VmView>
              <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                <VmInput
                  onChange={e => setpennik(e.target.value)}
                  value={pennik}
                  typeInput={'text'}
                  className="w-full py-1.5"
                />
              </VmView>
            </VmView>
            <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
              <VmView className="HubunganKeluarga text-neutral-700 text-sm font-normal">
                Hubungan Keluarga
              </VmView>

              <select
                onChange={e => {
                  setpenhubungan(e.target.value)
                }}
                value={penhubungan}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value={'0'} disabled>
                  Pilih Hubungan Keluarga
                </option>
                {[
                  {value: 1, text: 'Orang Tua'},
                  {value: 2, text: 'Anak'},
                  {value: 3, text: 'Pasangan (Suami/Istri)'},
                  {value: 4, text: 'Saudara'},
                  {value: 5, text: 'Lainnya'},
                ].map((hub, i) => (
                  <option key={i} value={hub.value}>
                    {hub.text}
                  </option>
                ))}
              </select>
            </VmView>
          </VmView>

          <VmView className="Left grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
            <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
              <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
                Jenis Kelamin
              </VmView>

              <select
                onChange={e => {
                  setpenjk(e.target.value)
                }}
                value={penjk}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="0" disabled>
                  Pilih Jenis Kelamin
                </option>
                <option value="Laki-laki">Laki-laki</option>
                <option value="Perempuan">Perempuan</option>
              </select>
            </VmView>
            <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
              <VmView className="NoTelepon text-neutral-700 text-sm font-normal">
                No. Telepon
              </VmView>

              <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                <VmInput
                  onChange={e => setpentelp(e.target.value)}
                  value={pentelp}
                  typeInput={'text'}
                  className="w-full py-1.5"
                />
              </VmView>
            </VmView>
            <VmView className="TextinputGroup self-stretch h-28 flex-col justify-center items-start gap-2 flex">
              <VmView className="Alamat text-neutral-700 text-sm font-normal">
                Alamat
              </VmView>

              <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                <VmInput
                  onChange={e => setpenalamat(e.target.value)}
                  value={penalamat}
                  typeInput={'textarea'}
                  className="w-full py-1.5"
                />
              </VmView>
            </VmView>
          </VmView>
        </VmView>

        <VmView className="flex flex-col gap-4 checkbox-wrapper">
          <VmView className="ConsentWrap justify-start items-start gap-3 flex">
            <VmView className="CheckboxWrap py-1 justify-start items-start gap-2.5 flex">
              {/* <VmView className="Checkbox w-4 bg-zinc-300" /> */}
              <input
                className="w-4 h-4"
                disabled={pasconsent == 1} // jika dia sudah setuju sebelumnya, maka disabled (ini berfungsi ketika ubah pasien)
                type="checkbox"
                name=""
                id=""
                checked={consent1}
                onChange={e => setconsent1(e.target.checked)}
              />
            </VmView>
            <VmView className="SayaMenjaminBahwaPasienSudahMembacaDanMenandatanganiFormConsentPembukaanDataDariSatusehat grow shrink basis-0 text-neutral-700 text-sm">
              Saya menjamin bahwa pasien sudah membaca dan menandatangani form
              consent pembukaan data dari SATUSEHAT
            </VmView>
          </VmView>
          <VmView className="ConsentWrap justify-start items-start gap-3 flex">
            <VmView className="CheckboxWrap py-1 justify-start items-start gap-2.5 flex">
              {/* <VmView className="Checkbox w-4 bg-zinc-300" /> */}
              <input
                className="w-4 h-4"
                disabled={pasconsent == 1} // jika dia sudah setuju sebelumnya, maka disabled (ini berfungsi ketika ubah pasien)
                type="checkbox"
                name=""
                id=""
                checked={consent2}
                onChange={e => setconsent2(e.target.checked)}
              />
            </VmView>
            <VmView className="SayaMenjaminBahwaPasienMenyutujuiUntukMenerimaDanMembukaDataPasienDariFasilitasPelayananKesehatanLainnyaMelaluiSatusehatUntukKepentinganPelayananKesahatanDanAtauRujukan grow shrink basis-0 text-neutral-700 text-sm">
              Saya menjamin bahwa pasien menyutujui untuk menerima dan membuka
              data pasien dari fasilitas pelayanan kesehatan lainnya melalui
              SATUSEHAT untuk kepentingan pelayanan kesahatan dan/atau rujukan
            </VmView>
          </VmView>
          <VmView className="ConsentWrap justify-start items-start gap-3 flex">
            <VmView className="CheckboxWrap py-1 justify-start items-start gap-2.5 flex">
              {/* <VmView className="Checkbox w-4 bg-zinc-300" /> */}
              <input
                className="w-4 h-4"
                disabled={pasconsent == 1} // jika dia sudah setuju sebelumnya, maka disabled (ini berfungsi ketika ubah pasien)
                type="checkbox"
                name=""
                id=""
                checked={consent3}
                onChange={e => setconsent3(e.target.checked)}
              />
            </VmView>
            <VmView className="SayaMenjaminBahwaPasienMenyutujuiDanMengetahuiBahwaBerdasarkanPeraturanMentriKesehatanNomor24Tahun2022TentangRekamMedisFasilitasPelayananKesehatanWajibMembukaAksesDanMengirimDataRekamMedisKepadaKementrianKesehatanMelaluiPlatformSatusehat grow shrink basis-0 text-neutral-700 text-sm">
              Saya menjamin bahwa pasien menyutujui dan mengetahui bahwa
              berdasarkan peraturan mentri kesehatan nomor 24 tahun 2022 tentang
              rekam medis, fasilitas pelayanan kesehatan wajib membuka akses dan
              mengirim data rekam medis kepada kementrian kesehatan melalui
              platform SATUSEHAT
            </VmView>
          </VmView>
        </VmView>
      </>
    )
  }

  const renderFieldPcare = () => {
    const {nmProvider, hubunganKeluarga} = pasienPcare
    return (
      <>
        <VmView className="OuterWrapperLabelPenanggungjawab flex-col justify-start items-start gap-2.5 flex gap-3">
          <VmView className="LabelPenanggungjawab self-stretch justify-start items-start gap-2.5 flex">
            <VmView className="PenanggungJawabDariPasien grow shrink basis-0 text-neutral-700 text-xl font-normal">
              PCare
            </VmView>
          </VmView>
        </VmView>

        <VmView className="ColContaine rbg-white rounded-xl shadow-lg shadow-zinc-300 justify-start items-start p-4">
          <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex mb-2">
            <VmView className="NamaLengkap text-neutral-700 text-sm font-normal">
              Entri Pendaftaran PCare
            </VmView>

            <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
              Ya{' '}
              <input
                type="radio"
                name="entri_pcare"
                id=""
                value={1}
                checked={pasienPcare.entry == 1}
                onChange={e =>
                  setPasienPcare({...pasienPcare, entry: e.target.value})
                }
                disabled={!pasienPcare.entryeditable}
              />
              Tidak{' '}
              <input
                type="radio"
                name="entri_pcare"
                id=""
                value={2}
                checked={pasienPcare.entry == 2}
                onChange={e =>
                  setPasienPcare({...pasienPcare, entry: e.target.value})
                }
                disabled={!pasienPcare.entryeditable}
              />
            </VmView>
          </VmView>

          <VmText className="font-normal">Pendaftaran</VmText>

          <VmView className="flex gap-6">
            <VmView className="Right grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="NamaLengkap text-neutral-700 text-sm font-normal">
                  Tanggal Pendaftaran
                </VmView>

                <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                  {tglDDMMMYYYY(tglkunjungan)}
                </VmView>
              </VmView>
              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="Nik text-neutral-700 text-sm font-normal">
                  Nama
                </VmView>
                <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                  {pasnama}
                </VmView>
              </VmView>
              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="HubunganKeluarga text-neutral-700 text-sm font-normal">
                  Tanggal Lahir
                </VmView>

                <VmView>{tglDDMMMYYYY(pastgllahir)}</VmView>
              </VmView>

              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
                  PPK Umum
                </VmView>

                <VmView>
                  {kdProvider}  {nmProvider ? `- ${nmProvider}` : ``}
                </VmView>
              </VmView>

              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="NoTelepon text-neutral-700 text-sm font-normal">
                  No. Rekam Medis
                </VmView>

                <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                  {pasrm}
                </VmView>
              </VmView>

              <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
                <VmView className="Alamat text-neutral-700 text-sm font-normal">
                  Perawatan
                </VmView>

                <select
                  disabled={pasienPcare.entry == 2}
                  onChange={e => {
                    setPasienPcare({
                      ...pasienPcare,
                      perawatan: e.target.value,
                    })
                  }}
                  value={pasienPcare.perawatan}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {[
                    {value: 10, text: 'Rawat Jalan'},
                    {value: 20, text: 'Rawat Inap'},
                    {value: 50, text: 'Promotif Preventif'},
                  ].map((hub, i) => (
                    <option key={i} value={hub.value}>
                      {hub.text}
                    </option>
                  ))}
                </select>
              </VmView>

              <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
                <VmInputCaption
                  min={0}
                  value={pasienPcare.keluhan || keluhanutama}
                  onChange={e => {
                    setkeluhanutama(e.target.value)
                    setPasienPcare({
                      ...pasienPcare,
                      keluhan: e.target.value,
                    })
                  }}
                  title={'Keluhan'}
                  typeInput="text"
                  disabled={pasienPcare.entry == 2}
                />
              </VmView>
            </VmView>

            <VmView className="Left grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
                  No. Kartu BPJS
                </VmView>

                <VmView>{pasnobpjs}</VmView>
              </VmView>

              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
                  Status Peserta
                </VmView>

                <VmView>{hubunganKeluarga}</VmView>
              </VmView>

              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
                  Jenis Kelamin
                </VmView>

                <VmView>{pasjk}</VmView>
              </VmView>

              <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
                <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
                  No. Handphone
                </VmView>

                <VmView>{pastelp}</VmView>
              </VmView>

              <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
                <VmView className="Alamat text-neutral-700 text-sm font-normal">
                  Jenis Kunjungan
                </VmView>

                <select
                  disabled={pasienPcare.entry == 2}
                  onChange={e => {
                    setPasienPcare({
                      ...pasienPcare,
                      jeniskunjungan: e.target.value,
                    })
                  }}
                  value={pasienPcare.jeniskunjungan}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {[
                    {value: 1, text: 'Kunjungan Sakit'},
                    {value: 2, text: 'Kunjungan Sehat'},
                  ].map((hub, i) => (
                    <option key={i} value={hub.value}>
                      {hub.text}
                    </option>
                  ))}
                </select>
              </VmView>

              <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
                <VmView className="Alamat text-neutral-700 text-sm font-normal">
                  Poli
                </VmView>

                <select
                  disabled={pasienPcare.entry == 2}
                  onChange={e => {
                    setPasienPcare({
                      ...pasienPcare,
                      kdPoli: e.target.value,
                    })
                  }}
                  value={pasienPcare.kdPoli}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {pasienPcare.listPoli?.map((hub, i) => (
                    <option key={i} value={hub.value}>
                      {hub.text}
                    </option>
                  ))}
                </select>
              </VmView>
            </VmView>
          </VmView>

          <VmText className="font-normal my-4">Pemeriksaan Fisik</VmText>
          <VmView className="flex gap-6">
            <VmView className="Right grow shrink basis-0 flex-col justify-start items-center gap-6 flex">
              <VmInputCaption
                min={0}
                value={pasienPcare.tb}
                onChange={e => {
                  setPasienPcare({
                    ...pasienPcare,
                    tb: e.target.value,
                  })
                }}
                title={'Tinggi Badan (cm)'}
                typeInput="number"
                disabled={pasienPcare.entry == 2}
              />

              <VmInputCaption
                min={0}
                value={pasienPcare.lingkar}
                onChange={e => {
                  setPasienPcare({
                    ...pasienPcare,
                    lingkar: e.target.value,
                  })
                }}
                title={'Lingkar Perut (cm)'}
                typeInput="number"
                disabled={pasienPcare.entry == 2}
              />
            </VmView>

            <VmView className="Left grow shrink basis-0 flex-col justify-start items-center gap-6 flex">
              <VmInputCaption
                min={0}
                value={pasienPcare.bb}
                onChange={e => {
                  setPasienPcare({
                    ...pasienPcare,
                    bb: e.target.value,
                  })
                }}
                title={'Berat Badan (kg)'}
                typeInput="number"
                disabled={pasienPcare.entry == 2}
              />
            </VmView>
          </VmView>

          <VmText className="font-normal my-4">Tekanan Darah</VmText>
          <VmView className="flex gap-6">
            <VmView className="Right grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
              <VmInputCaption
                min={0}
                value={pasienPcare.sistole}
                onChange={e => {
                  setPasienPcare({
                    ...pasienPcare,
                    sistole: e.target.value,
                  })
                }}
                title={'Sistole (mmHg)'}
                typeInput="number"
                disabled={pasienPcare.entry == 2}
              />

              <VmInputCaption
                min={0}
                value={pasienPcare.resprate}
                onChange={e => {
                  setPasienPcare({
                    ...pasienPcare,
                    resprate: e.target.value,
                  })
                }}
                title={'Respiratory Rate (/ minute)'}
                typeInput="number"
                disabled={pasienPcare.entry == 2}
              />
            </VmView>

            <VmView className="Left grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
              <VmInputCaption
                min={0}
                value={pasienPcare.diastole}
                onChange={e => {
                  setPasienPcare({
                    ...pasienPcare,
                    diastole: e.target.value,
                  })
                }}
                title={'Diastole (mmHg)'}
                typeInput="number"
                disabled={pasienPcare.entry == 2}
              />

              {/* <input type='number' min={0}/> */}
              <VmInputCaption
                min={0}
                value={pasienPcare.heartrate}
                onChange={e => {
                  setPasienPcare({
                    ...pasienPcare,
                    heartrate: e.target.value,
                  })
                }}
                title={'Heart Rate (bpm)'}
                typeInput="number"
                disabled={pasienPcare.entry == 2}
              />
            </VmView>
          </VmView>
        </VmView>
      </>
    )
  }

  const updatePasien = () => {
    let pasconsent = consent1 || consent2 || consent3 ? 1 : 0 // Jika salah satu centang, maka anggap centang semua

    let params = {
      uid: dctid,
      data: {
        pasnobpjs,
        pasnokk,
        pasnama,
        pasgoldarah,
        pasjk,
        pastgllahir,
        pasalamat,
        pasidkec: pasidkec,
        pastelp,
        paspekerjaan,
        pasemail,
        pasalergi,
        pasalergimakanan,
        pasnoktp,
        pasrm,
        // pasparentid,
        passtatussambung: 0,
        agid,
        pendid,
        pendnama,
        agnama,
        pasid_satusehat,
        pasconsent,

        // data penanggungjawab
        penid,
        pennama,
        pennik,
        penhubungan,
        penjk,
        pentelp,
        penalamat,
        insertpen: pennik != penanggungjawabPrev.pennik ? 1 : 0,
      },
    }

    // console.log('updatePasien', params)
    return axios
      .post(
        `${APIDOC}/pasien-v2/update?id=${pasid}`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil POST
        if (res.status != 1) {
          alertok.current?.show({message: res.message + ' [TP0002]'})
          return
        }

        // Catat Log Dokter
        // catatLogDokter({
        //   keterangan: `Mengubah pasien ${pasnama}`,
        //   menu: 'Pelayanan - Registrasi Pasien',
        //   url: 'prd-tra-registrasi-pasien',
        //   ip: '127.0.0.1',
        //   uid: dctid,
        // })

        if (!empty(pasid_satusehat)) {
          // Update consent pasien SATUSEHAT
          updateConsent()
        }

        // this.props.navigation.navigate(
        //   'menuPilihPasienRegistrasiDokterPribadi',
        //   {shouldReload: true}
        // )
      })
      .catch(e => {
        alertok.current?.show({
          message: 'Terjadi kesalahan saat Mengubah Pasien. [TP0001]',
        })
        console.log(
          'Terjadi Kesalahan saat Mengubah Pasien',
          e.response?.data ?? e.message
        )
      })
  }

  const updateConsent = () => {
    let params = {
      reg: 'dbdok',
      a: 'dk13',
      kl_id: '19',
      pasid_satusehat,
      userid: dctid,
    }
    axios
      .post(
        `${APISATSET}/satusehat-pasien/update-consent`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('berhasil update consent Pasien', res)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Update Consent [TP006]', e.message)
      })
  }

  const buatKunjungan = async (kunpcareno = '') => {
    let nama_lengkap = identitas.nama_lengkap

    setloading(true)
    await axios
      .post(
        `${APIDOC}/kunjungan-v2/create`,
        QueryString.stringify({
          uid: dctid,
          data: {
            pasid,
            ['kunketerangan']: `Kunjungan Pasien ${pasnama}`,
            ['kunpcareno']: kunpcareno,
            ['kuntgl']: tglYMD(tglkunjungan),
          },
          tglreg: tglYMDHMS(tglkunjungan),
          // tglreg: new Date
        })
      )
      .then(({data: res}) => {
        // console.error('buatKunjungan', res)
        // let selectedPoli = {polnama: 'Poli Dokter', polid: 12}

        let data_satusehat = {
          // 'kunnomer': "KJ123",
          kunnomer: res['kunnomer'],
          pasid_satusehat,
          pasnama,
          dokid_satusehat: identitas['dokid_satusehat'],
          doknama: nama_lengkap,
          polid_satusehat: identitas['polid_satusehat'],
          polnama: 'Ruang Pemeriksaan',
        }

        let params_satusehat = {
          data_satusehat: JSON.stringify(data_satusehat),
          userid: dctid,
          klid: dctid,
          appid: 'dk13',
          reg: 'dbdok',
          kunid: res['kunid'],
        }

        console.log('buatKunjungan', params_satusehat)
        triggerEventBus(
          'kunjungan',
          'satusehat-kunjungan/create',
          params_satusehat
        )

        // Catat Log Dokter
        catatLogDokter({
          keterangan: 'Menambahkan kunjungan ' + res['kunnomer'],
          menu: 'Pelayanan - Registrasi Pasien',
          url: 'prd-tra-registrasi-pasien',
          ip: '127.0.0.1',
          uid: dctid,
        })
        alertok.current?.show({
          message: 'Berhasil membuat kunjungan pasien.',
          type: 'success',
        })

        setkunid(res['kunid'])
        setstate('finish_registrasi')
        // this.setState({
        //   state: 'finish_registrasi',
        //   kunid: res['kunid'],
        // })
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat Simpan Data Kunjungan Pasien',
          e.response?.data ?? e.message
        )
      })
      .finally(() => {
        // setloading(false)
      })
  }

  const resetForm = () => {
    // Reset State ke default
    setpasid(initial.pasid)
    setpasnobpjs(initial.pasnobpjs)
    setpasnokk(initial.pasnokk)
    setpasnama(initial.pasnama)
    setpasgoldarah(initial.pasgoldarah)
    setpasjk(initial.pasjk)
    setpastgllahir(initial.pastgllahir)
    setpasalamat(initial.pasalamat)
    setpasidkec(initial.pasidkec)
    setpastelp(initial.pastelp)
    setpaspekerjaan(initial.paspekerjaan)
    setpasemail(initial.pasemail)
    setpasalergi(initial.pasalergi)
    setpasalergimakanan(initial.pasalergimakanan)
    setpasnoktp(initial.pasnoktp)
    setpasrm(initial.pasrm)
    setpasid_satusehat(initial.pasid_satusehat)
    setpasconsent(initial.pasconsent)
    setpasumur(initial.pasumur)
    setagid(initial.agid)
    setpendid(initial.pendid)
    setpennama(initial.pennama)
    setpennik(initial.pennik)
    setpenhubungan(initial.penhubungan)
    setpenjk(initial.penjk)
    setpentelp(initial.pentelp)
    setpenalamat(initial.penalamat)

    // Reset State ke default registrasi pasien
    setstate('registrasi_pasien')
    setkunid(0)
  }

  // console.log({params})
  let sudahPilihPasien = !empty(pasid)
  return (
    <VmView>
      <ProgressFullScreen visible={loading} />
      <AlertOk ref={alertok} />
      <VmText className="text-center text-2xl mt-12">Registrasi Pasien</VmText>

      <VmView className="p-4 flex flex-col gap-8">
        {/* 2 Column Wrapper */}
        <VmView className="w-full justify-start items-start gap-6 flex mt-12">
          <VmView className="grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
            <VmView className="self-stretch flex-col justify-center items-start gap-2 flex">
              <VmView className="text-neutral-700 text-sm font-normal">
                Tanggal Kunjungan
              </VmView>

              <VmView className="w-full">
                <DatePicker
                  locale={'id'}
                  selected={tglkunjungan}
                  onChange={date => {
                    settglkunjungan(date)
                    // setPeriodeakhir(date)
                  }}
                  className="text-xs w-full"
                  wrapperClassName="w-full"
                  withPortal
                  customInput={
                    <VmView className="w-full p-2.5 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 flex">
                      <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                      <VmView className="text-neutral-700 text-sm font-bold">
                        {tglDDMMMYYYY(tglkunjungan)}
                      </VmView>
                    </VmView>
                  }
                  // minDate={periodeawal}
                  maxDate={new Date()}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                >
                  <VmView />
                </DatePicker>
              </VmView>
            </VmView>
          </VmView>
          <VmView className="grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
            <VmView className="self-stretch flex-col justify-center items-start gap-2 flex">
              <VmView className="text-neutral-700 text-sm font-normal">
                No. RM
              </VmView>
              <VmView className="self-stretch justify-start items-center gap-2 flex">
                <VmView className="flex-1 relative">
                  <VmInput value={pasrm} disabled className="w-full py-2" />
                  {/* Tombol Clear */}
                  {sudahPilihPasien ? (
                    <VmView
                      className="p-1 bg-red-500 rounded-full justify-center items-start gap-2.5 flex absolute top-2 right-2 cursor-pointer"
                      onClick={() => {
                        resetForm()
                        clearLocalStorage()
                      }}
                    >
                      <VmXMarkIcon className="w-[20px] text-white" />
                    </VmView>
                  ) : null}
                </VmView>
                {/* <VmView className="grow shrink basis-0 bg-white rounded-xl border-2 border-gray-200" /> */}
                <VmView
                  className="p-2 bg-blue-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/prd-registrasi-pasien/pilih-pasien/${dctid}?from=index&pasid_exception=${pasid}` // pasid_exception agar pasien yang sudah ada tidak muncul di pilh pasien
                    )
                  }}
                >
                  <VmMagnifyingGlassIcon className="w-[24px] text-white" />
                </VmView>
                <VmView
                  className="p-2 bg-emerald-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                  onClick={() => {
                    navigate(
                      '/prd-registrasi-pasien/tambah-pasien/' +
                        dctid +
                        '?mode=tambah_pasien'
                    )
                  }}
                >
                  <VmPlusIcon className="w-[24px] text-white" />
                </VmView>
              </VmView>
            </VmView>
          </VmView>
        </VmView>

        {sudahPilihPasien ? renderContent() : null}

        {/* 2 Button Wrapper */}
        <VmView className="w-full justify-between items-center flex">
          {(() => {
            switch (state) {
              case 'registrasi_pasien':
                return (
                  <VmView
                    className="px-4 py-2.5 bg-emerald-500 rounded-lg justify-center items-center gap-2.5 flex cursor-pointer"
                    onClick={e => {
                      // console.log('onClick Tombol Simpan')
                      validate()
                    }}
                  >
                    <VmView className="text-white text-sm font-bold ">
                      Daftar Kunjungan
                    </VmView>
                  </VmView>
                )

              case 'finish_registrasi':
                return (
                  <>
                    <VmButton
                      className={classNames('rounded-lg', 'px-4 py-2')}
                      onClick={() => {
                        resetForm()
                      }}
                      color="danger"
                      full={false}
                      tag={''}
                    >
                      {'Kembali'}
                    </VmButton>
                    <VmButton
                      className={classNames('rounded-lg', 'px-4 py-2')}
                      onClick={() => {
                        resetForm()
                        navigate(
                          `/prd-tra-pemeriksaan-pasien/create?kid=${kunid}&pid=${pasid}&dctid=${dctid}`
                        )
                      }}
                      color="success"
                      full={false}
                      tag={''}
                    >
                      {'Lanjut Pemeriksaan Pasien'}
                    </VmButton>
                  </>
                )
            }
          })()}
        </VmView>
      </VmView>
    </VmView>
  )
}
