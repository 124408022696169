import axios from 'axios'
import classNames from 'classnames'
import {VmButton, VmInput, VmPicker, VmText, VmView} from 'components'
import {VmUserIcon} from 'components/icons/solid'
import ModalPembayaran from 'components/reusable_modal/ModalPembayaran'
import ModalPilihanPaket from 'components/reusable_modal/ModalPilihanPaket'
import VmInputCaption from 'components/VmInputCaption'
import {VmHeader, FullErrorView} from 'molecules'
import QueryString from 'qs'
import React from 'react'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {empty, toCurrency} from 'utils/functions'
import {iconMap} from 'components/icons/IconMap'
import {hidering} from 'template/PrdStrukSetting'
import {renderField} from 'template/PrdUbahProfil/Index'
import {
  VmArchiveBoxIcon,
  VmClockIcon,
  VmReceiptPercentIcon,
  VmTagIcon,
  VmDiscountIcon
} from 'components/icons/outline'
import moment from 'moment'
import VmToast from 'molecules/toast/VmToast'
import VmToastBody from 'molecules/toast/VmToastBody'
import VmToastHeader from 'molecules/toast/VmToastHeader'
import VmToaster from 'molecules/toast/VmToaster'
import AlertOk from 'components/AlertOk'
import { RgxMobileDevice } from 'utils/core'

const {REACT_APP_BASE_URL_DOKTER: BASE_URL, REACT_APP_PPN_REGISTRASI: PPN_REGISTRASI} = process.env

const lstmenu = [
  {
    type: 1,
    title: 'Durasi (Bulan)',
    Icon: VmClockIcon,
    label: 'input-name',
    field: 'prd_durasi',
    typeInput: 'text',
    maxLength: 100,
    bindValue: true,
  },
  {
    type: 1,
    title: 'Harga',
    Icon: VmTagIcon,
    label: 'input-name',
    field: 'pktHarga',
    typeInput: 'text',
    maxLength: 100,
    bindValue: true,
  },
  {
    type: 1,
    title: 'Diskon',
    Icon: VmDiscountIcon,
    label: 'input-name',
    field: 'pktDiskon',
    typeInput: 'text',
    maxLength: 100,
    bindValue: true,
  },
  {
    type: 12,
    title: 'PPN',
    label: 'input-name',
    field: [
      {
        field: 'pktPajak',
        typeInput: 'text',
        maxLength: 100,
        bindValue: true,
        disabled: true,
        Icon: VmReceiptPercentIcon,
      },
      {
        field: 'pktPajakText',
        typeInput: 'text',
        maxLength: 100,
        bindValue: true,
        disabled: true,
        Icon: VmReceiptPercentIcon,
      },
    ],
  },
]

function Perpanjangan() {
  const [toast, addToast] = useState(0)
  const toaster = useRef()
  const [listPaket, setListPaket] = useState([])
  const [selectedPaketId, setSelectedPaketId] = useState(119)
  const [mPembayaranVisible, setMPembayaranVisible] = useState(false)
  const [mPilihanPaket, setMPilihanPaket] = useState(false)

  /**
   * State toggle
   * upgrade = bisa pilih paket yang lain
   * perpanjangan = ambil paket aktif sebelumnya
   */
  const [jenis, setJenis] = useState('upgrade')

  /**
   * upgrade = Mode User daftar baru (sebelumnya belum punya paket)
   * perpanjangan = Mode User perpanjangan paket sebelumnya / upgrade paket lain
   */
  const [mode, setMode] = useState('')
  const [dataRegistrasi, setDataRegistrasi] = useState('')
  const [dataRegistrasiDetail, setDataRegistrasiDetail] = useState({})
  const [identitas, setIdentitas] = useState({})
  const [device, setDevice] = useState(false)

  const detectDevice = () => {
    let isMobile = RgxMobileDevice.test(navigator.userAgent)
    setDevice(isMobile ? 'mobile' : 'web')
  }

  useEffect(() => {
    detectDevice()
  }, [])

  let [searchParams, setSearchParams] = useSearchParams()
  const [uid, setUid] = useState(searchParams.get('dctid'))

  const alertok = useRef()

  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/backend-data-registrasi/paket-registrasi-dokter`,
        QueryString.stringify({})
      )
      .then(({data}) => {
        // console.log('List Paket Dokter', data)
        setListPaket(data)
      })

    axios
      .post(
        `${BASE_URL}/backend-data-registrasi/find-registrasi-dokter`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data}) => {
        console.log('Data Registrasi: ', data)

        let dataRegistrasi = data.data
        if (dataRegistrasi.reg_aktif == 1 || dataRegistrasi.reg_aktif == 6) {
          // Redirect ke halaman Konfirmasi Pembayaran
          navigate(`/site/bayarperpanjangan?dctid=${uid}`)
          return
        }

        if (dataRegistrasi) {
          // Jika punya paket sebelumnya, Maka ini mode Perpanjangan
          setMode('perpanjangan')
          setJenis('perpanjangan')
        } else {
          // Masih belum punya paket
          setMode('upgrade')
          setJenis('upgrade')
        }
        setDataRegistrasi(data.data)
        setDataRegistrasiDetail(data.detail)
        setSelectedPaketId(dataRegistrasi.reg_version)
      })

    // Get Data User
    axios
      .post(
        `${BASE_URL}/ap-user-dokter/view?id=${uid}`,
        QueryString.stringify({
          a: 'dk13',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let identitas = res.data
        setIdentitas(identitas)
        // console.log('data user', identitas)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get data user')
      })
  }, [])

  let paketValueMap = listPaket.map(pkt => ({
    value: pkt.pktid,
    text: pkt.pktNama,
  }))

  let selectedPaket = listPaket.find(pkt => pkt.pktid == selectedPaketId)
  let currentAktifPaket = listPaket.find(
    pkt => pkt.pktid == dataRegistrasi.reg_version
  )

  let harga, diskon, hargaFinal, pajakRegistrasi, total
  if (empty(selectedPaket)) {
    harga = '0'
    diskon = '0'
    hargaFinal = '0'
  } else {
    total = selectedPaket?.pktHarga - selectedPaket?.pktDiskon
    pajakRegistrasi = total * (parseFloat(PPN_REGISTRASI) / 100)
    hargaFinal = total + pajakRegistrasi
  }

  const navigate = useNavigate()

  const exampleColorToast = (color = 'primary', message) => (
    <VmToast className={color} show={true}>
      <VmToastHeader closeButton>
        <VmText className="mr-auto">Perpanjangan & Upgrade</VmText>
      </VmToastHeader>
      <VmToastBody>{message}</VmToastBody>
    </VmToast>
  )

  return (
    <>
      <AlertOk ref={alertok} />
      <div
        className={`px-4 ${device === "web" ? "pt-[16rem]" : "pt-[21rem]"} pb-24 bg-fixed bg-repeat-x`}
        style={
          {
            // backgroundColor: 'lightgray',
          }
        }
      >
        {/* Modal Pembayaran */}
        <ModalPembayaran
          onClose={() => {
            setMPembayaranVisible(false)
          }}
          visible={mPembayaranVisible}
          selectedPaket={selectedPaket ?? {}}
        />

        {/* Modal Pilihan Paket */}
        <ModalPilihanPaket
          data={[...listPaket]}
          onClose={() => {
            setMPilihanPaket(false)
          }}
          visible={mPilihanPaket}
          selectedPaket={selectedPaket ?? {}}
          onSelected={pkt => {
            // console.log(pkt, 'Pkt gan')
            setSelectedPaketId(pkt.pktid)
            setMPilihanPaket(false)
          }}
        />

        <VmView
          id="fixed-container"
          style={{
            backgroundImage:
              "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!-- Generator: Gravit.io --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='isolation:isolate' viewBox='0 0 360 538' width='360pt' height='538pt'%3E%3Cdefs%3E%3CclipPath id='_clipPath_HOQln3xPEuJQi1BVRsYHuJRL7LCJW0r6'%3E%3Crect width='360' height='538'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23_clipPath_HOQln3xPEuJQi1BVRsYHuJRL7LCJW0r6)'%3E%3ClinearGradient id='_lgradient_0' x1='0' y1='0.5' x2='1' y2='0.5' gradientTransform='matrix(360 0 0 180 0 0)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0%25' stop-opacity='1' style='stop-color:rgb(3 131 204)'/%3E%3Cstop offset='98.33333333333333%25' stop-opacity='1' style='stop-color:rgb(41 167 240)'/%3E%3C/linearGradient%3E%3Crect x='0' y='0' width='360' height='180' transform='matrix(1 0 0 1 0 0)' fill='url(%23_lgradient_0)'/%3E%3Cdefs%3E%3Cfilter id='sHJ0knPpD2PQfwQGcM1DmUlYeQQastcw' x='-200%25' y='-200%25' width='400%25' height='400%25' filterUnits='objectBoundingBox' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur xmlns='http://www.w3.org/2000/svg' stdDeviation='0'/%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23sHJ0knPpD2PQfwQGcM1DmUlYeQQastcw)'%3E%3Cpath d=' M 0 149 L 360 96 C 360 96 360 96 360 96 L 360 185 C 360 185 360 185 360 185 L 0 185 C 0 185 0 185 0 185 L 0 96 C 0 96 0 149 0 149 Z ' fill='rgb(7 131 202)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
          }}
          className="fixed top-0 left-0 right-0 px-4"
        >
          <VmHeader
            style={{}}
            HeaderName={'Menu'}
            HeaderType={'Perpanjangan & Upgrade'}
            className=" py-4 z-10 top-0 left-4 right-4"
            classNameTitle="!pl-4"
            mode={'bg-blue-gradient'}
          />

          {/* Card Information */}
          <div className=" z-10 card-container left-4 right-4">
            <div className={`card p-4 bg-white rounded-xl shadow-lg ${device === "web" ? 'mt-8' : ''}`}>
              <VmText className="text-center text-primary font-bold mb-4">
                Data Registrasi
              </VmText>

              <div className="flex mb-2">
                <IdentityField
                  icon="user-outline"
                  fieldName="Nama Dokter"
                  value={identitas?.nama_lengkap}
                />
                <IdentityField
                  icon="archive-outline"
                  fieldName="Paket Aktif"
                  value={dataRegistrasiDetail?.regd_version_nama ?? '-'}
                />
              </div>

              {/* <div className="flex mb-2">
                <p className="flex-1">{identitas?.nostr}</p>
                <p className="flex-1">{identitas?.nostr}</p>
              </div> */}

              <div className="flex mb-2">
                <IdentityField
                  icon="credit-card-outline"
                  fieldName="No. STR"
                  value={identitas?.nostr ?? '-'}
                />

                <IdentityField
                  icon="calendar-outline"
                  fieldName="Tanggal Expired"
                  value={
                    !empty(dataRegistrasiDetail?.regd_selesai)
                      ? moment(dataRegistrasiDetail?.regd_selesai).format(
                          'DD MMM YYYY'
                        )
                      : '-'
                  }
                />
              </div>

              <div className="flex mb-2">
                <IdentityField
                  icon="user-outline"
                  fieldName="Spesialisasi"
                  value={identitas?.ket_suspend ?? '-'}
                />
              </div>
            </div>
          </div>

          <div className=" z-10 left-0">
            <VmToaster push={toast} placement="top-start" />
          </div>

          {/* Small Section Putih */}
        </VmView>

        {/* Form Field */}
        <div id="form-field" className="">
          <VmText
            className={classNames(
              'font-bold text-center text-primary mb-4 text-primary'
            )}
          >
            Pilih Paket
          </VmText>

          {/* Tampilkan toggle tombol hanya jika mode = perpanjangan */}
          {mode == 'perpanjangan' ? (
            <div className="flex justify-center mb-4">
              <VmButton
                // key={JSON.stringify(im)}
                aria-label={'Perpanjangan'}
                variant="ghost"
                active={jenis == 'perpanjangan'}
                className={
                  'shadow-lg !h-10 !px-4 !radius-lg flex-1 md:flex-none mr-1 ml-4'
                }
                // style={{flex: 0.33}}
                onClick={() => {
                  setJenis('perpanjangan')

                  // Set Paket terpilih paket aktif saat ini
                  setSelectedPaketId(dataRegistrasi.reg_version)
                }}
              >
                {'Perpanjangan'}
              </VmButton>

              <VmButton
                // key={JSON.stringify(im)}
                aria-label={'Upgrade'}
                variant="ghost"
                active={jenis == 'upgrade'}
                className={
                  'shadow-lg !h-10 !px-4 !radius-lg flex-1 md:flex-none ml-1 mr-4'
                }
                // style={{flex: 0.33}}
                onClick={() => {
                  setJenis('upgrade')
                  setSelectedPaketId(false)
                }}
              >
                {'Upgrade'}
              </VmButton>
            </div>
          ) : null}

          {/* Pilihan Upgrade Paket */}
          {jenis == 'upgrade' ? (
            <>
              <VmView
                // key={key}
                className="flex flex-nowrap flex-col p-2 justify-between"
              >
                <VmText className="text-xs text-blue-500">
                  {'Pilihan Upgrade'}
                </VmText>
                <VmView className="flex flex-row mt-1 bg-gray-200 rounded-md">
                  <VmView
                    className={classNames(
                      'h-12',
                      'p-3.5 w-12 rounded-l-md border-none text-blue-500'
                    )}
                  >
                    <VmArchiveBoxIcon
                      className={classNames('h-full', 'w-full')}
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                  </VmView>

                  <VmButton
                    onClick={() => {
                      setMPilihanPaket(true)
                    }}
                    variant="secondary"
                    full
                    className={classNames('text-xs text-left text-gray-400')}
                  >
                    {selectedPaket?.pktNama ?? 'Silakan Pilih Paket'}
                  </VmButton>
                  
                </VmView>
              </VmView>
              <VmText className="text-xs text-danger px-2">
                <b>note :</b> anda tidak perlu memilih upgrade bila hanya ingin
                perpanjangan.
              </VmText>
            </>
          ) : null}

          {/* Tampilkan list field2 */}
          {lstmenu.map((im, index) =>
            renderField(im, index, {
              ...selectedPaket,
              pktHarga: toCurrency(selectedPaket?.pktHarga ?? 0),
              pktDiskon: toCurrency(selectedPaket?.pktDiskon ?? 0),
              pktPajak: `${toCurrency(PPN_REGISTRASI??0)}%`,
              pktPajakText: toCurrency(pajakRegistrasi??0),
              
            })
          )}
        </div>

        {/* Fixed Footer */}
        <div
          id="footer"
          className="fixed flex bottom-0 right-0 left-0 bg-white shadow p-4"
        >
          <div className="flex-1">
            <VmText className="text-sm text-primary font-bold">Total</VmText>
            <VmText className="text-danger font-bold">
              {toCurrency(hargaFinal)}
            </VmText>
          </div>

          <VmButton
            id="b-upgrade"
            label="SimpanSetting"
            className={classNames(
              hidering,
              // 'w-[-webkit-fill-available]',
              'shadow-lg max-h-20 bg-gradient-to-r from-orange-400 to-yellow-300 py-3',
              'rounded-lg text-sm text-white bottom-[30px] left-[30px] right-[30px]',
              'flex-1'
            )}
            onClick={_ => {
              if (!selectedPaket && jenis == 'upgrade') {
                alertok.current?.show({
                  message: 'Silakan memilih pilihan paket terlebih dahulu.',
                })
                // addToast(
                //   exampleColorToast(
                //     `warning`,
                //     'Silakan memilih pilihan paket terlebih dahulu.'
                //   )
                // )
                return
              }
              // setMPembayaranVisible(true)
              navigate(`/perpanjangan/metode?dctid=${uid}`, {
                state: {...selectedPaket, mode},
              })
            }}
          >
            {jenis == 'upgrade' ? 'Upgrade' : 'Perpanjangan'}
          </VmButton>
        </div>
      </div>
    </>

    // <div id="container-gan" className="container mx-auto">

    // </div>
  )
}

export const IdentityField = ({icon, fieldName, value}) => {
  let VmIcon = iconMap[icon]
  return (
    <div className="flex flex-1 align-items-center">
      <VmIcon width={25} height={25} className={'text-primary'} />
      <div className="flex-1 ml-1">
        <VmText className="text-xs font-bold text-primary mb-1">
          {fieldName}
        </VmText>
        <VmText style={{overflowWrap: 'anywhere'}} className="text-xs">
          {value}
        </VmText>
      </div>
    </div>
  )
}

export default Perpanjangan
